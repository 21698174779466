import { Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";
import * as _ from "lodash";

import { FuseConfigService } from "@fuse/services/config.service";
import { FuseSidebarService } from "@fuse/components/sidebar/sidebar.service";

import { navigation } from "app/navigation/navigation";
import { Store, select } from "@ngrx/store";
import { RootState, selectCompany, selectUser } from "app/core/store";
import { TokenService } from "app/services/token/token.service";
import { SetCompany } from "app/core/store/company/company.action";
import { SetProfile } from "app/core/store/profile/profile.action";
import { SetUser } from "app/core/store/user/user.action";
import { environment } from "environments/environment";
import { fuseAnimations } from "@fuse/animations";
import { BookingService } from "app/services/booking/booking.service";
import { MessagingService } from "app/services/messaging/messaging.service";
import { SharedService } from "app/services/shared/shared.service";
import { SetBookingSeaFreightInbound } from "app/core/store/booking-sea-freight-inbound/sea-freight-inbound.action";
import { MatDialog } from "@angular/material/dialog";
import { ClaimsComponent } from "../reusable/claims/claims.component";
import { PopUpComponent } from "../pop-up/pop-up.component";
import { SelectTruckingTypeDialogComponent } from "../reusable/select-trucking-type-dialog/select-trucking-type-dialog.component";
import { Router } from "@angular/router";

@Component({
  selector: "toolbar",
  templateUrl: "./toolbar.component.html",
  styleUrls: ["./toolbar.component.scss"],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations,
})
export class ToolbarComponent implements OnInit, OnDestroy {
  horizontalNavbar: boolean;
  rightNavbar: boolean;
  hiddenNavbar: boolean;
  languages: any;
  navigation: any;
  selectedLanguage: any;
  userStatusOptions: any[];
  company: any;
  serviceTypes: any[];
  services: any;

  // Private
  private _unsubscribeAll: Subject<any>;

  // KYC STATUS
  private _KYCStatus: any;
  private _checkOld: any;

  showCreate: boolean = false;
  private _isMaylasian: boolean = false;

  /**
   * Constructor
   *
   * @param {FuseConfigService} _fuseConfigService
   * @param {FuseSidebarService} _fuseSidebarService
   * @param {TranslateService} _translateService
   */
  constructor(
    private _fuseConfigService: FuseConfigService,
    private _fuseSidebarService: FuseSidebarService,
    private _translateService: TranslateService,
    private store: Store<RootState>,
    private tokenService: TokenService,
    private bookingService: BookingService,
    private messagingService: MessagingService,
    private sharedService: SharedService,
    private dialog: MatDialog,
    private router: Router
  ) {
    // Set the defaults
    this.userStatusOptions = [
      {
        title: "Online",
        icon: "icon-checkbox-marked-circle",
        color: "#4CAF50",
      },
      {
        title: "Away",
        icon: "icon-clock",
        color: "#FFC107",
      },
      {
        title: "Do not Disturb",
        icon: "icon-minus-circle",
        color: "#F44336",
      },
      {
        title: "Invisible",
        icon: "icon-checkbox-blank-circle-outline",
        color: "#BDBDBD",
      },
      {
        title: "Offline",
        icon: "icon-checkbox-blank-circle-outline",
        color: "#616161",
      },
    ];

    this.languages = [
      {
        id: "en",
        title: "English",
        flag: "us",
      },
      {
        id: "tr",
        title: "Turkish",
        flag: "tr",
      },
    ];

    this.navigation = navigation;

    // Set the private defaults
    this._unsubscribeAll = new Subject();

    this.store
      .pipe(select(selectCompany))
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(
        (res) => {
          if (res) {
            const {
              country: { countryId, countryName, countryCode },
            } = res;

            this._isMaylasian =
              countryCode === "MY" && countryName.toLowerCase() === "malaysia";
          }
        },
        (err) => {
          console.warn(err);
        }
      );

    this.store
      .pipe(select(selectUser))
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {
        if (res) {
          this.company = res.company;
          this._KYCStatus = res.kyc.kycStatusCode;
          this._checkOld = res.company.status;

          this.getCreateBooking();

          this.bookingService
            .getServiceTypes(res.company.serviceId)
            .subscribe((res) => {
              this.services = res.data;

              let serviceTypes = [
                {
                  serviceName: "Sea Freight - Outbound",
                  serviceTypeId: this.services[0].serviceTypeId,
                },
                {
                  serviceName: "Sea Freight - Inbound",
                  serviceTypeId: this.services[0].serviceTypeId,
                },
                // {
                //   serviceName: "Trucking",
                //   serviceTypeId: services[1].serviceTypeId,
                // },
                // {
                //     serviceName: "Customs Brokerage",
                //     serviceTypeId: services[2].serviceTypeId
                // },
                // {
                //     serviceName: "Freight Forwarder",
                //     serviceTypeId: services[3].serviceTypeId
                // },
                // {
                //     serviceName: "Warehouse",
                //     serviceTypeId: services[4].serviceTypeId
                // },
              ];

              this.serviceTypes = serviceTypes;
            });
        }
      });
    // Get User Information
  }

  getCreateBooking() {
    if (this._checkOld && this._KYCStatus != "REJ") {
      this.showCreate = true;
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Subscribe to the config changes
    this._fuseConfigService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((settings) => {
        this.horizontalNavbar = settings.layout.navbar.position === "top";
        this.rightNavbar = settings.layout.navbar.position === "right";
        this.hiddenNavbar = settings.layout.navbar.hidden === true;
      });

    // Set the selected language from default languages
    this.selectedLanguage = _.find(this.languages, {
      id: this._translateService.currentLang,
    });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle sidebar open
   *
   * @param key
   */
  toggleSidebarOpen(key): void {
    this._fuseSidebarService.getSidebar(key).toggleOpen();
  }

  /**
   * Search
   *
   * @param value
   */
  search(value): void {
    // Do your search here...
  }

  /**
   * Set the language
   *
   * @param lang
   */
  setLanguage(lang): void {
    // Set the selected language for the toolbar
    this.selectedLanguage = lang;

    // Use the selected language for translations
    this._translateService.use(lang.id);
  }

  logout() {
    this.tokenService.setToken({});
    this.store.dispatch(new SetCompany(null));
    this.store.dispatch(new SetUser(null));
    this.store.dispatch(new SetProfile(null));
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    location.replace(environment.loginUrl);

    // For Booking
    this.messagingService.UnRegisterDevice(
      localStorage.getItem("reservationId")
    );
  }

  //for draft
  clicked() {
    this.store.dispatch(new SetBookingSeaFreightInbound(null));
    this.sharedService.setSupplementaryTrucking(false);
    this.sharedService.setCollectedDraftId(false);
  }

  // For Claims
  openReferenceModal() {
    if (!this._isMaylasian) {
      return this.openPopUp({
        data: "information",
        title: "Info",
        description:
          "This function is not available to your account. Your account may be registered in another country..",
      });
    }
    this.dialog.open(ClaimsComponent, {
      panelClass: "reference-booking",
      width: "50%",
      autoFocus: null,
      disableClose: true,
    });
  }

  openSelectTruckingModal() {
    this.dialog.open(SelectTruckingTypeDialogComponent, {
      panelClass: "reference-booking",
      width: "40%",
      autoFocus: null,
      disableClose: true,
      data: {
        serviceTypeId: this.services[1].serviceTypeId,
      },
    });
  }

  openPopUp(data: any) {
    return this.dialog.open(PopUpComponent, {
      width: "20%",
      panelClass: "contact-form-dialog",
      data: {
        data: data.data,
        title: data.title,
        description: data.description,
      },
    });
  }

  clickSlotBooking() {
    this.router.navigate([`/main/slot-booking`], { replaceUrl: true });
  }
}
