import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { environment } from "environments/environment";
import { Store } from "@ngrx/store";
import { RootState } from "app/core/store";
import { SetCompany } from "app/core/store/company/company.action";
import { User } from "app/core/models/user.model";
import { Observable } from "rxjs";
import { AccessControlService } from "app/services/access-control/access-control.service";
import { SetMenuPermissions } from "app/core/store/menu-permission/menu-permission.action";
import { SetUser } from "app/core/store/user/user.action";
import { FuseUtils } from "@fuse/utils";

@Injectable({
  providedIn: "root",
})
export class ProfileService {
  constructor(
    private http: HttpClient,
    private store: Store<RootState>,
    private accessControlService: AccessControlService
  ) {}

  /**
   * Get Current Logged in User
   *
   * @returns {Promise} promise
   * @memberof ProfileService
   */
  getUser() {
    return new Promise<any>((resolve, reject) => {
      let token = JSON.parse(localStorage.getItem("token"));
      let companyServiceId =
        token && token.service && token.service.companyServiceId;
      this.http
        .get<any>(
          `${environment.clientManagement}/clients/api/v1/profile/service/${companyServiceId}`
        )
        .subscribe(
          (res) => {
            const user: User = res.data.profile;
            this.store.dispatch(new SetUser(user));

            const {
              profile: {
                user: { username },
              },
            } = res.data;

            this.accessControlService
              .getMenuPermissions(username)
              .subscribe((result) => {
                if (result) {
                  this.store.dispatch(
                    new SetMenuPermissions(result && result.data)
                  );
                }
              });
            resolve("");
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  /**
   * Get Current Logged in Company
   *
   * @returns {Promise} promise
   * @memberof ProfileService
   */
  initGetCompanyProfile() {
    return new Promise<any>((resolve, reject) => {
      this.http
        .get<any>(
          `${environment.clientManagement}/clients/api/v1/company/details`
        )
        .subscribe(
          (res) => {
            this.store.dispatch(new SetCompany(res.data.company));
            resolve("");
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  /**
   * Fetch the Company Profile / Details
   *
   * @returns {Observable<any>} response
   * @memberof ProfileService
   */
  getCompanyProfile(): Observable<any> {
    return this.http.get<any>(
      `${environment.clientManagement}/clients/api/v1/company/details`
    );
  }

  /**
   * Fetch the User Profile / Details
   *
   * @returns {Observable<any>} response
   * @memberof ProfileService
   */
  getMyProfile(): Observable<any> {
    return this.http.get<any>(
      `${environment.clientManagement}/clients/api/v1/user/profile`
    );
  }

  /**
   * Updates Company Profile
   *
   * @param {*} payload
   * @returns {Observable<any>} response
   * @memberof ProfileService
   */
  updateCompanyProfile(payload): Observable<any> {
    return this.http.put<any>(
      `${environment.clientManagement}/clients/api/v1/company`,
      payload
    );
  }

  /**
   * Updates User Profile
   *
   * @param {*} payload
   * @returns {Observable<any>} response
   * @memberof ProfileService
   */
  updateMyProfile(payload): Observable<any> {
    return this.http.put<any>(
      `${environment.clientManagement}/clients/api/v1/user`,
      payload
    );
  }

  /**
   * Get all audit logs in Company Profile
   *
   * @returns {Observable<any>} response
   * @memberof ProfileService
   */
  getCompanyProfileAuditLogs(): Observable<any> {
    return this.http.get<any>(
      `${environment.clientManagement}/clients/api/v1/company/profile/logs`
    );
  }

  /**
   * Gets the information of Audit Log
   *
   * @param {string} id
   * @returns {Observable<any>}
   * @memberof ProfileService
   */
  viewCompanyProfileAuditLog(id: string): Observable<any> {
    return this.http.get<any>(
      `${environment.clientManagement}/clients/api/v1/logs/details/${id}`
    );
  }

  /**
   * Get all audit logs in My Profile
   *
   * @returns {Observable<any>} response
   * @memberof ProfileService
   */
  getMyProfileAuditLogs(payload): Observable<any> {
    return this.http.get<any>(
      `${environment.clientManagement}/clients/api/v2/users/profile/logs`,
      { params: FuseUtils.FormatQuery(payload) }
    );
  }

  getAuditLogs(company): Observable<any> {
    return this.http.get<any>(
      `${environment.clientManagement}/clients/api/v1/logs/User/${company}`
    );
  }

  getUserViewLogs(auditLogId): Observable<any> {
    return this.http.get<any>(
      `${environment.clientManagement}/clients/api/v1/logs/details/${auditLogId}`
    );
  }

  getUserAuditLogs(userId, payload): Observable<any> {
    return this.http.get<any>(
      `${environment.clientManagement}/clients/api/v2/users/${userId}/logs`,
      { params: FuseUtils.FormatQuery(payload) }
    );
  }

  downloadCompanyProfileAuditLogs(): Observable<any> {
    return this.http.get<any>(
      `${environment.clientManagement}/clients/api/v1/company/profile/logs/download`,
      {
        responseType: "blob" as "json",
      }
    );
  }

  downloadMyProfileAuditLogs(payload): Observable<any> {
    let headers = new HttpHeaders({
      Accept: "text/csv",
    });
    return this.http.get<any>(
      `${environment.clientManagement}/clients/api/v2/users/profile/logs/export`,
      {
        params: FuseUtils.FormatQuery(payload),
        headers,
        responseType: "blob" as "json",
      }
    );
  }

  getAdditionalInformation(): Observable<any> {
    return this.http.get<any>(
      `${environment.clientManagement}/clients/api/v1/kyc/company-sections`
    );
  }

  submitCompanyIndividual(payload, type): Observable<any> {
    return this.http.post<any>(
      `${environment.clientManagement}/clients/api/v1/kyc/company-beneficial-owners/${type}`,
      payload
    );
  }

  companyDirectors(payload, action): Observable<any> {
    return this.http.post<any>(
      `${environment.clientManagement}/clients/api/v1/kyc/company-directors/${action}`,
      payload
    );
  }

  getDocumentType(): Observable<any> {
    return this.http.get<any>(
      `${environment.clientManagement}/clients/api/v1/kyc/document-types`
    );
  }

  getTotalEmployee(): Observable<any> {
    return this.http.get<any>(
      `${environment.clientManagement}/clients/api/v1/kyc/total-number-of-employees`
    );
  }

  submitCompanyStructure(payload): Observable<any> {
    return this.http.post<any>(
      `${environment.clientManagement}/clients/api/v1/kyc/company-structure/submit`,
      payload
    );
  }

  draftCompanyStructure(payload): Observable<any> {
    return this.http.post<any>(
      `${environment.clientManagement}/clients/api/v1/kyc/company-structure/draft`,
      payload
    );
  }

  getPartnerList(): Observable<any> {
    return this.http.get<any>(
      `${environment.globalCMS}/global/cms/api/v1/third-party/codes`
    );
  }

  addPartner(payload) {
    return this.http.post<any>(
      `${environment.clientManagement}/clients/api/v1/company/third-party`,
      payload
    );
  }

  getPartnerListing(): Observable<any> {
    return this.http.get<any>(
      `${environment.clientManagement}/clients/api/v1/company/third-party`
    );
  }

  deletePartner(id): Observable<any> {
    return this.http.delete<any>(
      `${environment.clientManagement}/clients/api/v1/company/third-party/${id}`
    );
  }

  editPartner(payload): Observable<any> {
    return this.http.put<any>(
      `${environment.clientManagement}/clients/api/v1/company/third-party`,
      payload
    );
  }

  getThirdPartyAuditLogs(): Observable<any> {
    return this.http.get<any>(
      `${environment.clientManagement}/clients/api/v1/company/third-party/logs`
    );
  }

  downloadThirdPartyAuditLogs(payload): Observable<any> {
    let responseType = `${
      payload.fileType === "csv" ? "text" : "blob"
    }` as "json";
    let params = new HttpParams()
      .set("orderBy", payload.orderBy)
      .set("pageSize", payload.pageSize);

    let headers = new HttpHeaders({
      Accept: payload.fileType === "csv" ? "csv" : "xlxs",
    });

    return this.http.get<any>(
      `${environment.clientManagement}/clients/api/v1/company/third-party/logs/download`,
      {
        params: params,
        headers,
        responseType: responseType,
      }
    );
  }

  seaFreightClaims(payload): Observable<any> {
    return this.http.post<any>(
      `${environment.transactionManagement}/otm/api/v1/transactions/sea-freight/claim`,
      payload
    );
  }

  seaFreightCreateClaim(payload): Observable<any> {
    return this.http.post<any>(
      `${environment.transactionManagement}/otm/api/v1/transactions/sea-freight/create-claimed`,
      payload
    );
  }

  getWalletDetails(companyGuid) {
    return this.http.get<any>(
      `${environment.clientManagement}/clients/api/v1/kyc/wallet/${companyGuid}`
    );
  }

  walletInfo(payload) {
    return this.http.get<any>(
      `${environment.clientManagement}/clients/api/v1/wallet/wallet-balance/`,
      {
        params: payload,
      }
    );
  }
}
