import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "environments/environment";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class UploaderService {
  constructor(private http: HttpClient) {}

  s3UploadImage(body): any {
    // return this.http.post(`${environment.globalCMS}/global/cms/api/v1/subMenuContent/upload`, body);
    return this.http.post(
      `${environment.globalCMS}/global/cms/api/v1/subMenuContent/upload`,
      body
    );
  }

  singleUploadImage(body): any {
    return this.http.post(
      `${environment.globalCMS}/global/cms/api/v1/file/upload`,
      body
    );
  }

  multipleUploadImage(body): any {
    return this.http.post(
      `${environment.globalCMS}/global/cms/api/v1/file/upload-multiple`,
      body
    );
  }

  singleUploadTransaction(body): any {
    return this.http.post(
      `${environment.transactionManagement}/otm/api/v1/transactions/file/upload`,
      body
    );
  }

  multipleUploadTransaction(body): any {
    return this.http.post(
      `${environment.transactionManagement}/otm/api/v1/transactions/file/upload-multiple`,
      body
    );
  }

  getTransactionImage(payload, arg1?): Observable<any> {
    let endpoint =
      arg1 === "insurance"
        ? "/otm/api/v1/transactions/insurance/file/generate-presigned-url"
        : "/otm/api/v1/transactions/file/generate-presigned-url";

    return this.http.get(
      `${environment.transactionManagement}${endpoint}?path=${payload}`
    );
  }

  // getClientImage(payload): any {
  //   return this.http.get(`${environment.globalCMS}/global/cms/api/v1/file/pre-signed?path=${payload}`)
  // }

  getClientImage(payload): any {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };
    return this.http.post(
      `${environment.globalCMS}/global/cms/api/v1/file/pre-signed/single`,
      JSON.stringify(payload),
      httpOptions
    );
  }

  getCyImage(payload): any {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };
    return this.http.post(
      `${environment.transactionManagement}/otm/api/v1/container-yard/container-yard/pre-sign`,
      JSON.stringify(payload),
      httpOptions
    );
  }
}
