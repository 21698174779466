import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CountryService {

  constructor(
    private http: HttpClient
  ) { }

  getInitialInfo(): any {
    return this.http.get<any>(`${environment.globalCMS}/global/cms/api/v1/website/initialInfo`);
  }

  getCountryList(name = ''): Observable<any> {
    return this.http.get<any>(
      `${environment.globalCMS}/global/cms/api/v2/country/filter?countryName=${name}`
    );
  }

  getStateList(id, name = '', pageSize?): Observable<any> {
    return this.http.get<any>(
      `${environment.globalCMS}/global/cms/api/v2/country/${id}/states?stateName=${name}&pageSize=${pageSize ?? 5000}`
    );
  }

  getCityList(id, name = '', pageSize?): Observable<any> {
    return this.http.get<any>(
      `${environment.globalCMS}/global/cms/api/v2/state/${id}/cities?cityName=${name}&pageSize=${pageSize ?? 5000}`
    );
  }

  getStates(countryId): any {
    return this.http.get<any>(`${environment.globalCMS}/global/cms/api/v1/country/${countryId}/states`);
  }

  getCities(stateId): any {
    return this.http.get<any>(`${environment.globalCMS}/global/cms/api/v1/state/${stateId}/cities`);
  }
  async getCountryData() {
    return this.http.get('https://ipapi.co/json/').toPromise();

  }

  getCitiesList(countryID): Observable<any> {
    return this.http.get<any>(`${environment.truckingService}/oat/api/v1/GetCityList?countryId=${countryID}`);
  }
  getUnLoCodeList(countryName, stateOrCity, value): Observable<any> {
    return this.http.get<any>(`${environment.globalAggregator}/g/api/v1/unlocode/data?query=Country:${countryName},${stateOrCity}:${value}`);
  }

  getStateCity(data) {
    let arr = []
    for (const key in data) {
      if (data[key] == 0) {
        arr.unshift(`${key}=${data[key]}`)
      }
      if (data[key]) {
        arr.unshift(`${key}=${data[key]}`)
      }
    }
    const url = arr.join('&')
    // let params = new HttpParams().set('countryId',`${data.countryId}`);
    // if (data.stateCityFilter) {
    //   params = params.append('state', data.stateCityValue || '');
    //   params = params.append('city', '');
    // } else {

    //   params = params.append('city', data.stateCityValue || '');
    //   params = params.append('state', '');
    // }
    // params = params.append('pageNumber', data.pageNumber);
    // params = params.append('pageSize', data.pageSize);
    return this.http.get<any>(`${environment.globalAggregator}/g/api/v1/location/search?` + url)
  }
}
