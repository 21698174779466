import { Component, OnInit, Inject, ViewEncapsulation, ViewChild } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BookingService } from 'app/services/booking/booking.service';
import { fuseAnimations } from '@fuse/animations';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { element } from 'protractor';
import { PopUpComponent } from 'app/layout/components/pop-up/pop-up.component';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { SelectTypeShipperDialogComponent } from '../select-type-shipper-dialog/select-type-shipper-dialog.component';
import { FuseUtils } from '@fuse/utils';

@Component({
  selector: 'app-select-shipper-dialog',
  templateUrl: './select-shipper-dialog.component.html',
  styleUrls: ['./select-shipper-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations,
})

export class SelectShipperDialogComponent implements OnInit {

  shipperList: any[];
  selectedShipper: any;
  showButton = false;
  loading: boolean = false;

  searchFilter = new FormControl();
  filteredValues = '';

  dataLength = 0;
  pageNumber = 0;
  pageSize = 10;
  pageEvent: PageEvent;

  @ViewChild(MatPaginator, { static: true })
  paginator: MatPaginator;

  data: any;
  company: any;

  constructor(
    public dialogRef: MatDialogRef<SelectShipperDialogComponent>,
    private dialog: MatDialog,
    private bookingService: BookingService,
    @Inject(MAT_DIALOG_DATA) _data: any,
  ) {

    if (_data) {
      this.data = _data;
      this.company = this.data?.company;
    }

    this.getShipperContacts(this.filteredValues);
    this.loading = true;

  }

  ngOnInit(): void {
    this.searchFilter.valueChanges
      .pipe(debounceTime(600))
      .pipe(distinctUntilChanged())
      .subscribe((searchFilter) => {
        this.filteredValues = searchFilter;
        this.pageNumber = 0;
        this.getShipperContacts(this.filteredValues)
      })
  }

  /**
   * Paginator
   *
   * @param {*} event
   * @memberof SelectShipperDialogComponent
   */
  public handlePage(event) {
    this.pageNumber = event.pageIndex;
    this.pageSize = event.pageSize;
    this.getShipperContacts(this.filteredValues);
  }


  checkSelected() {
    let currentShipper = this.data.selectedShipper;

    if (currentShipper === null) {
      this.company["isSelected"] = false;
      this.shipperList.forEach(element => {
        element.isSelected = false;
      });

    } else {
      if (currentShipper) {
        let selectedShipper = currentShipper;

        this.shipperList.forEach(element => {
          if (element.contactId === selectedShipper.contactId) {
            element.isSelected = true;
          } else {
            element.isSelected = false;
          }
        });

        if (this.company.companyId === selectedShipper.contactId) {
          this.company["isSelected"] = true;
        } else {
          this.company["isSelected"] = false;
        }
      }
    }

  }

  selectShipper(shipper, type): void {
    if (type === "shipper") {
      this.shipperList.forEach(element => {
        element.isSelected = false;
      });
      shipper.isSelected = true;

      this.dialogRef.close({
        shipperList: this.shipperList,
        shipper
      });
    } else {
      shipper.isSelected = true;
      let p = shipper && shipper.phone
      let phoneNumber = p.phonePrefix + p.phone;

      let bpShipper = {
        cityProvince: shipper && shipper.city && shipper.city.cityName,
        contactId: shipper && shipper.companyId,
        contactName: shipper && shipper.companyName,
        country: shipper && shipper.country && shipper.country.countryName,
        email: shipper && shipper.emailAddress,
        phoneNumber: phoneNumber,
        imageUrl: shipper && shipper.imageUrl
      }

      shipper = bpShipper;

      this.dialogRef.close({
        shipperList: this.shipperList,
        shipper
      });
    }
  }


  addGuestShipper(): void {
    this.dialogRef.close();
    this.dialog.open(SelectTypeShipperDialogComponent, {
      width: '500px',
      data: {
        type: 0
      }
    });

  }

  /**
   *
   *
   * @memberof SelectShipperDialogComponent
   */
  openPopUp(data) {
    this.dialogRef.close(SelectShipperDialogComponent);
    return this.dialog.open(PopUpComponent, {
      panelClass: "contact-form-dialog",
      data: {
        data: data.data,
        title: data.title,
        description: data.description,
        showDelete: data.showDelete,
      },
    });
  }

  getShipperContacts(filteredValues) {
    this.bookingService.getContactsInbound(filteredValues, this.pageNumber, this.pageSize, 1).subscribe(res => {
      if (res) {
        this.loading = false;
        const { pagedResponse } = res.data;
        this.shipperList = pagedResponse.map(element => {
          return {
            ...element,
            contactType: element && element.contactType === 1 ? "Registered" : "Guest" || '',
            isSelected: false,
          }
        })

        this.checkSelected();
        this.pageNumber = res?.data?.pageNumber;
        this.pageSize = res?.data?.pageSize;
        this.dataLength = res?.data?.totalRecords;

      }
    }, error => {
      console.warn(error);
      this.openPopUp(
        {
          data: 'error',
          title: 'ERROR !',
          description: this.errorHandler(error),
          disableClose: true
        }
      );
    }
    )
  }

  /**
   * Error Handler
   *
   */
  errorHandler(data: any) {
    return FuseUtils.handleError(data);
  }

}
