import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpEvent,
  HttpHandler,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { finalize, map, catchError, switchMap } from "rxjs/operators";
import * as camelcase from "camelcase-keys";
import { TokenService } from "app/services/token/token.service";
import { PopUpComponent } from "app/layout/components/pop-up/pop-up.component";
import { MatDialog } from "@angular/material/dialog";
import { RootState } from "../store";
import { Store } from "@ngrx/store";
import { SetCompany } from "../store/company/company.action";
import { SetUser } from "../store/user/user.action";
import { SetProfile } from "../store/profile/profile.action";
import { environment } from "environments/environment";
import { SetPorts } from "../store/port/port.action";

@Injectable({
  providedIn: "root",
})
export class HttpInterceptorService implements HttpInterceptor {
  currentRequestCount = 0;

  constructor(
    private tokenService: TokenService,
    private dialog: MatDialog,
    private store: Store<RootState>
  ) {}

  injectToken(request: HttpRequest<any>) {
    if (localStorage) {
      return this.getToken(request);
    }
    location.replace(environment.loginUrl);
  }

  getToken(request) {
    let token =
      localStorage.getItem("token") != "{}"
        ? localStorage.getItem("token")
        : null;

    if (token) {
      let parsedToken = JSON.parse(token);
      const { idToken, tokenType } =
        parsedToken &&
        parsedToken.cognito &&
        parsedToken.cognito.authenticationResult;

      return request.clone({
        setHeaders: {
          Authorization: `${tokenType} ${idToken}`,
        },
      });
    }
    location.replace(environment.loginUrl);
  }
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // start the loading spinner here
    this.currentRequestCount++;

    return next.handle(this.injectToken(req)).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          if (error.url.includes("refresh")) {
            // if refresh token is also expired
            this.openPopUp({
              data: "error",
              title: "Token Expired",
              description: "Your Token has expired, please re-login.",
            });
          } else {
            // if access token is expired
            return this.tokenService.generateNewToken().pipe(
              switchMap((res: any) => {
                let token = JSON.parse(localStorage.getItem("token"));

                let {
                  authenticationResult,
                  challengeName,
                  challengeParameters,
                } = res && res.data;

                token = {
                  ...token,
                  cognito: {
                    authenticationResult: {
                      ...token.cognito.authenticationResult,
                      ...authenticationResult,
                    },
                    challengeName,
                    challengeParameters,
                    clientMetadata: {},
                    sessionId: null,
                  },
                };

                this.tokenService.setToken(token);
                localStorage.setItem("token", JSON.stringify(token));
                return next.handle(this.injectToken(req));
              })
            );
          }
        }
        if (error.status === 400) {
          if (error.url.includes("refresh")) {
            this.openPopUp({
              data: "error",
              title: "Token Expired",
              description: "Your Token has expired, please re-login.",
            });
          }
        }
        if (error.status === 500) {
          return throwError("Something went wrong!");
        }
        // handle http errors
        return throwError(error.error);
      }),
      // before
      // map((event: HttpEvent<any>) => {
      //   if (event instanceof HttpResponse) {
      //     // transform all json response to camelCase
      //     const modEvent = event.clone({
      //       body: camelcase(event.body, { deep: true }),
      //     });
      //     return modEvent;
      //   }
      // }),
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          if (
            event.headers.get("content-type").includes("json") ||
            event.headers.get("content-type").includes("javascript")
          ) {
            // transform all json response to camelCase
            const modEvent = event.clone({
              body: camelcase(event.body, { deep: true }),
            });
            return modEvent;
          }
          return event;
        }
      }),
      finalize(() => {
        this.currentRequestCount--;
        if (this.currentRequestCount === 0) {
          // hide loading spinner if all requests are done
        }
      })
    );
  }

  /**
   * Open Modal, After closing will redirect to Login
   *
   * @param {*} data
   * @memberof HttpInterceptorService
   */
  openPopUp(data: any) {
    this.dialog
      .open(PopUpComponent, {
        panelClass: "contact-form-dialog",
        data: {
          data: data.data,
          title: data.title,
          description: data.description,
        },
      })
      .afterClosed()
      .subscribe((response) => {
        if (response === "close") {
          this.tokenService.setToken({});
          this.store.dispatch(new SetCompany(null));
          this.store.dispatch(new SetUser(null));
          this.store.dispatch(new SetProfile(null));
          this.store.dispatch(new SetPorts(null));
          localStorage.removeItem("token");
          localStorage.removeItem("refreshToken");
          location.replace(environment.loginUrl);
        }
      });
  }

  /**
   * Open Modal for Standard Errors
   *
   * @param {*} data
   * @memberof HttpInterceptorService
   */
  openModal(data: any) {
    this.dialog.open(PopUpComponent, {
      panelClass: "contact-form-dialog",
      data: {
        data: data.data,
        title: data.title,
        description: data.description,
      },
    });
  }
}
