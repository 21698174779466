<mat-toolbar
  class="logo mb-24 mb-sm-0 accent p-12"
  fxLayout="row"
  fxLayoutAlign="space-between start"
>
  <span class="logo-text h3 font-weight-800 font-size-14">
    Add Guest
    {{ type == 0 ? "Shipper" : type == 1 ? "Consignee" : "Notify Party" }}
    Information
  </span>
  <div class="cursor-pointer" aria-label="Close dialog" (click)="close()">
    <mat-icon>cancel</mat-icon>
  </div>
</mat-toolbar>

<form fxLayout="row" class="guest-shipper-container">
  <div fxLayout="column" class="p-24" fxFlex>
    <form [formGroup]="guestDetailsForm">
      <div
        fxLayout="row"
        fxLayoutAlign="space-around start"
        fxFlex
        fxLayoutGap="12px"
      >
        <div fxLayout="column" fxFlex="30">
          <div
            class="board-list-item"
            ngx-dropzone
            (change)="validateImagesCompany($event)"
            fxLayout="column"
            fxLayoutAlign="center center"
            [ngStyle]="{
              'border-color':
                submitted && guestDetailsForm.controls.Image.errors
                  ? 'red'
                  : '#E0E0E0'
            }"
          >
            <div class="board-name" *ngIf="!Image">
              <mat-icon class="s-50" style="color: #b2b2b2">
                add_photo_alternate
              </mat-icon>
            </div>
            <img class="image-size" *ngIf="Image" [src]="Image" />
            <div class="UploadCompany" *ngIf="!Image">Upload Image</div>
            <div
              class="validation"
              [ngStyle]="{ color: formatPicture ? 'red' : 'black' }"
              *ngIf="!Image"
            >
              Format: jpg, png
            </div>
            <div
              class="validation"
              [ngStyle]="{ color: maxSize ? 'red' : 'black' }"
              *ngIf="!Image"
            >
              Size: 5 mb (max)
            </div>
          </div>
        </div>

        <div fxLayout="column" fxFlex="70">
          <mat-form-field appearance="outline" floatLabel="always" class="mb-8">
            <mat-label>Company Name</mat-label>
            <input
              matInput
              formControlName="GuestName"
              minlength="6"
              maxlength="160"
              required
            />
            <mat-error
              *ngIf="guestDetailsForm.get('GuestName').hasError('required')"
            >
              Company Name is required!
            </mat-error>
            <mat-error
              *ngIf="guestDetailsForm.get('GuestName').hasError('minlength')"
            >
              Company Name must be at least 6 characters.
            </mat-error>
            <mat-error
              *ngIf="guestDetailsForm.get('GuestName').hasError('maxlength')"
            >
              Company Name must not exceed 160 characters.
            </mat-error>
          </mat-form-field>
          <div fxLayout="row" fxLayoutAlign="space-between" class="mb-8">
            <mat-form-field
              appearance="outline"
              floatLabel="always"
              fxFlex="48"
            >
              <mat-label>First Name</mat-label>
              <input
                maxlength="50"
                matInput
                formControlName="FirstName"
                required
              />
              <mat-error
                *ngIf="guestDetailsForm.get('FirstName').hasError('required')"
              >
                First Name is required
              </mat-error>
              <mat-error
                *ngIf="guestDetailsForm.get('FirstName').hasError('minlength')"
              >
                First Name must be at least 1 character.
              </mat-error>
              <mat-error
                *ngIf="guestDetailsForm.get('FirstName').hasError('maxlength')"
              >
                First Name must not exceed 50 characters.
              </mat-error>
            </mat-form-field>
            <mat-form-field
              appearance="outline"
              floatLabel="always"
              fxFlex="48"
            >
              <mat-label>Last Name</mat-label>
              <input
                maxlength="50"
                matInput
                formControlName="LastName"
                required
              />
              <mat-error
                *ngIf="guestDetailsForm.get('LastName').hasError('required')"
              >
                Last Name is required
              </mat-error>
              <mat-error
                *ngIf="guestDetailsForm.get('LastName').hasError('minlength')"
              >
                Last Name must be at least 1 character.
              </mat-error>
              <mat-error
                *ngIf="guestDetailsForm.get('LastName').hasError('maxlength')"
              >
                Last Name must not exceed 50 characters.
              </mat-error>
            </mat-form-field>
          </div>
          <div fxLayout="row" fxLayoutAlign="space-between" class="mb-8">
            <mat-form-field
              appearance="outline"
              floatLabel="always"
              fxFlex="48"
            >
              <mat-label>Email Address</mat-label>
              <input
                matInput
                maxlength="74"
                formControlName="EmailAddress"
                required
              />
              <mat-error
                *ngIf="
                  guestDetailsForm.get('EmailAddress').hasError('required')
                "
              >
                Company Email is required!
              </mat-error>
              <mat-error
                *ngIf="guestDetailsForm.get('EmailAddress').hasError('email')"
              >
                Please enter a valid email address.
              </mat-error>
              <mat-error
                *ngIf="
                  guestDetailsForm.get('EmailAddress').hasError('maxlength')
                "
              >
                Email Address must not exceed 74 characters
              </mat-error>
              <mat-error
                *ngIf="
                  guestDetailsForm.get('EmailAddress').hasError('minlength')
                "
              >
                Email Address must atleast 10 characters
              </mat-error>
            </mat-form-field>
            <mat-form-field
              appearance="outline"
              floatLabel="always"
              fxFlex="48"
            >
              <mat-label>Telephone Number</mat-label>
              <span class="mr-12">
                <mat-select formControlName="PhoneNumberPrefix" required>
                  <mat-option
                    *ngFor="let codes of callingCodes"
                    [value]="codes.countryId"
                  >
                    {{ codes.callingCode }}
                  </mat-option>
                </mat-select>
              </span>
              <input
                (click)="$event.stopPropagation()"
                maxlength="15"
                matInput
                formControlName="PhoneNumber"
                required
              />
              <mat-error
                *ngIf="guestDetailsForm.get('PhoneNumber').hasError('required')"
              >
                Telephone Number is required!
              </mat-error>
              <mat-error
                *ngIf="
                  guestDetailsForm.get('PhoneNumber').hasError('minlength')
                "
              >
                Telephone Number must be at least 5 characters.
              </mat-error>
              <mat-error
                *ngIf="
                  guestDetailsForm.get('PhoneNumber').hasError('maxlength')
                "
              >
                Telephone Number must not exceed 15 characters.
              </mat-error>
              <mat-error
                *ngIf="guestDetailsForm.get('PhoneNumber').hasError('pattern')"
              >
                This entry can only contain numbers
              </mat-error>
            </mat-form-field>
          </div>
          <div fxLayout="row" fxLayoutAlign="space-between" class="mb-8">
            <mat-form-field
              appearance="outline"
              floatLabel="always"
              fxFlex="48"
            >
              <mat-label>Mobile Number</mat-label>
              <span class="mr-12">
                <mat-select formControlName="MobileNumberPrefix" required>
                  <mat-option
                    *ngFor="let codes of callingCodes"
                    [value]="codes.countryId"
                  >
                    {{ codes.callingCode }}
                  </mat-option>
                </mat-select>
              </span>
              <input
                (click)="$event.stopPropagation()"
                maxlength="15"
                matInput
                formControlName="MobileNumber"
                required
              />
              <mat-error
                *ngIf="
                  guestDetailsForm.get('MobileNumber').hasError('required')
                "
              >
                Mobile Number is required!
              </mat-error>
              <mat-error
                *ngIf="
                  guestDetailsForm.get('MobileNumber').hasError('minlength')
                "
              >
                Mobile Number must be at least 5 characters.
              </mat-error>
              <mat-error
                *ngIf="
                  guestDetailsForm.get('MobileNumber').hasError('maxlength')
                "
              >
                Mobile Number must not exceed 15 characters.
              </mat-error>
              <mat-error
                *ngIf="guestDetailsForm.get('MobileNumber').hasError('pattern')"
              >
                This entry can only contain numbers
              </mat-error>
            </mat-form-field>
            <mat-form-field
              appearance="outline"
              floatLabel="always"
              fxFlex="48"
            >
              <mat-label>Fax Number</mat-label>
              <span class="mr-12">
                <mat-select formControlName="FaxNumberPrefix" required>
                  <mat-option
                    *ngFor="let codes of callingCodes"
                    [value]="codes.countryId"
                  >
                    {{ codes.callingCode }}
                  </mat-option>
                </mat-select>
              </span>
              <input
                (click)="$event.stopPropagation()"
                maxlength="15"
                matInput
                formControlName="FaxNumber"
                required
              />
              <mat-error
                *ngIf="guestDetailsForm.get('FaxNumber').hasError('required')"
              >
                Fax Number is required!
              </mat-error>
              <mat-error
                *ngIf="guestDetailsForm.get('FaxNumber').hasError('minlength')"
              >
                Fax Number must be at least 5 characters.
              </mat-error>
              <mat-error
                *ngIf="guestDetailsForm.get('FaxNumber').hasError('maxlength')"
              >
                Fax Number must not exceed 15 characters.
              </mat-error>
              <mat-error
                *ngIf="guestDetailsForm.get('FaxNumber').hasError('pattern')"
              >
                This entry can only contain numbers
              </mat-error>
            </mat-form-field>
          </div>
          <mat-form-field appearance="outline" floatLabel="always" class="mb-8">
            <mat-label>Complete Address</mat-label>
            <input
              matInput
              formControlName="AddressLine"
              minlength="10"
              maxlength="255"
              required
            />
            <mat-error
              *ngIf="guestDetailsForm.get('AddressLine').hasError('required')"
            >
              Complete Address is required!
            </mat-error>
            <mat-error
              *ngIf="guestDetailsForm.get('AddressLine').hasError('minlength')"
            >
              Complete Address must be at least 10 characters.
            </mat-error>
            <mat-error
              *ngIf="guestDetailsForm.get('AddressLine').hasError('maxlength')"
            >
              Complete Address must not exceed 255 characters.
            </mat-error>
          </mat-form-field>
          <div fxLayout="row" fxLayoutAlign="space-between" class="mb-8">
            <mat-form-field
              appearance="outline"
              floatLabel="always"
              fxFlex="48"
            >
              <mat-label>Country</mat-label>
              <mat-select formControlName="CountryName" required>
                <mat-option
                  [value]="country"
                  *ngFor="let country of countries"
                  (onSelectionChange)="selectedCountryData($event)"
                >
                  {{ country.countryName }}
                </mat-option>
              </mat-select>
              <mat-error>Country is required!</mat-error>
            </mat-form-field>
            <mat-form-field
              appearance="outline"
              floatLabel="always"
              fxFlex="48"
            >
              <mat-label>Province / State</mat-label>
              <mat-select formControlName="StateName" required>
                <mat-option
                  [value]="state"
                  *ngFor="let state of states"
                  (onSelectionChange)="selectedStateData($event)"
                >
                  {{ state.stateName }}
                </mat-option>
              </mat-select>
              <mat-error>State is required!</mat-error>
            </mat-form-field>
          </div>
          <div fxLayout="row" fxLayoutAlign="space-between" class="mb-8">
            <mat-form-field
              appearance="outline"
              floatLabel="always"
              fxFlex="48"
            >
              <mat-label>City</mat-label>
              <mat-select formControlName="CityName" required>
                <mat-option [value]="city" *ngFor="let city of cities">
                  {{ city.cityName }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field
              appearance="outline"
              floatLabel="always"
              fxFlex="48"
            >
              <mat-label>Postal Code</mat-label>
              <input
                matInput
                maxlength="10"
                formControlName="ZipCode"
                required
              />
              <mat-error
                *ngIf="guestDetailsForm.get('ZipCode').hasError('required')"
              >
                Postal Code is required!
              </mat-error>
              <mat-error
                *ngIf="guestDetailsForm.get('ZipCode').hasError('minlength')"
              >
                Postal Code must be at least 1 characters.
              </mat-error>
              <mat-error
                *ngIf="guestDetailsForm.get('ZipCode').hasError('pattern')"
              >
                This entry can only contain numbers
              </mat-error>
              <mat-error
                *ngIf="guestDetailsForm.get('ZipCode').hasError('maxlength')"
              >
                Postal Code must not exceed 10 characters.
              </mat-error>
            </mat-form-field>
          </div>
          <div fxLayout="row" fxLayoutAlign="end end" fxFlex="1 0 auto">
            <button mat-button class="grey-600 mr-8" (click)="close()">
              <mat-icon> keyboard_arrow_left </mat-icon>
              Cancel
            </button>
            <button
              mat-button
              class="blue-500 mr-8"
              (click)="submitNewUser()"
              [disabled]="guestDetailsForm.invalid"
            >
              <mat-icon> keyboard_arrow_right </mat-icon>
              Proceed
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</form>
