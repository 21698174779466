import {
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { FormBuilder, FormGroup, NgForm, Validators } from "@angular/forms";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { fuseAnimations } from "@fuse/animations";
import { SnackbarComponent } from "@fuse/components/snackbar/snackbar.component";
import { FuseSplashScreenService } from "@fuse/services/splash-screen.service";
import { FuseUtils } from "@fuse/utils";
import { Store, select } from "@ngrx/store";
import { Company } from "app/core/models/company.model";
import { RootState, selectCompany, selectUser } from "app/core/store";
import { ProfileService } from "app/services/profile/profile.service";
import { Subject } from "rxjs";
import { take, takeUntil } from "rxjs/operators";
import { PopUpComponent } from "../../pop-up/pop-up.component";

@Component({
  selector: "app-claims",
  templateUrl: "./claims.component.html",
  styleUrls: ["./claims.component.scss"],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations,
})
export class ClaimsComponent implements OnInit, OnDestroy {
  @ViewChild("addClaimsForm") addContainerForm: NgForm;
  claimsForm: FormGroup;

  isChecked: boolean = false;
  initialData: string = "BLNumber";
  public company: Company;
  public companyCode: any;
  payload: any;
  hasError: boolean = false;
  isSuccess: boolean = false;
  claimDetails: any;

  // Private KYC STATUS
  private _accountInitialization: any;

  private destroyed$ = new Subject();

  constructor(
    public matDialogRef: MatDialogRef<ClaimsComponent>,
    private _formBuilder: FormBuilder,
    private profileService: ProfileService,
    private _fuseSplashScreenService: FuseSplashScreenService,
    public dialog: MatDialog,
    private store: Store<RootState>,
    private router: Router,
    private _snackBar: MatSnackBar
  ) {
    this._fuseSplashScreenService.show();
    this.store
      .pipe(select(selectCompany))
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (res) => {
          this.company = res;
          this._fuseSplashScreenService.hide();
        },
        (err) => {
          this._fuseSplashScreenService.show();
          console.warn(err);
        }
      );
    this.store
      .pipe(select(selectUser))
      .pipe(take(1))
      .subscribe(
        (res) => {
          if (res) {
            this._accountInitialization = res.kyc?.kycStatusCode;
            this.companyCode = res.company;
          }
        },
        (err) => {
          console.warn(err);
        }
      );
  }

  ngOnInit(): void {
    this.claimsForm = this._formBuilder.group({
      typeOfReference: ["", Validators.required],
      blNumber: ["", this.requireBLNumberValidator(this.initialData)],
      bookingNumber: ["", this.requireBookingNumberValidator(this.initialData)],
      containerNumber: [""],
    });

    this.claimsForm.patchValue({
      typeOfReference: this.initialData,
    });
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  requireBookingNumberValidator(predicate) {
    return (formControl) => {
      if (predicate !== "BookingNumber") {
        return null;
      }
      return Validators.required(formControl);
    };
  }
  requireBLNumberValidator(predicate) {
    return (formControl) => {
      if (predicate !== "BLNumber") {
        return null;
      }
      return Validators.required(formControl);
    };
  }

  submit() {
    let data = this.claimsForm.getRawValue();

    const { typeOfReference, blNumber, bookingNumber, containerNumber } = data;
    if (typeOfReference.toLowerCase() === "blnumber") {
      this.payload = {
        blNumber: blNumber,
        containerNumbers: containerNumber ? [containerNumber] : [],
      };
    } else {
      this.payload = {
        bookingNumber: bookingNumber,
        containerNumbers: containerNumber ? [containerNumber] : [],
      };
    }
    if (containerNumber) {
      this.payload = {
        ...this.payload,
        isInitialCall: false,
      };
    }
    this.claimsAPI();
  }

  claimsAPI() {
    this._fuseSplashScreenService.show();
    this.profileService.seaFreightClaims(this.payload).subscribe(
      (result) => {
        this._fuseSplashScreenService.hide();
        let value = result.data[0];
        this.isSuccess = true;
        this.hasError = false;
        this.claimDetails = {
          ...this.payload,
          ...value,
        };
        this.payload = {
          ...this.payload,
          companyCode: this.companyCode?.code,
          bookingPartyId: this.companyCode?.companyId,
          bookingParty: this.companyCode?.name,
          bookingPartyImage: this.companyCode?.image,
          bookingPartyAddressLine: this.company.addressLine,
          bookingPartyLandline:
            this.company.phone.phonePrefix + " " + this.company.phone.phone,
          bookingPartyFax:
            this.company.fax.faxPrefix + " " + this.company.fax.fax,
          bookingPartyCountryId: this.company.country.countryId,
          bookingPartyCountryCode: this.company.country.countryCode,
          bookingPartyCountryName: this.company.country.countryName,
          serviceRole: this.companyCode?.service,
        };
      },
      (error) => {
        this._fuseSplashScreenService.hide();
        if (error.errors.length > 0) {
          return this.openPopUp({
            data: "error",
            title: "ERROR !",
            description: this.errorhandling(error),
          });
        }
        this.hasError = true;
        this.isChecked = false;
      }
    );
  }

  createClaim() {
    this._fuseSplashScreenService.show();
    this.profileService.seaFreightCreateClaim(this.payload).subscribe(
      (result) => {
        this._fuseSplashScreenService.hide();
        this._snackBar.openFromComponent(
          SnackbarComponent,
          FuseUtils.snackBarConfig("Success!", "success", result.message)
        );
        this.matDialogRef.close();
        this.router.navigate(
          [
            `/main/transaction-management/booking-reservations/details/${result?.data?.reservationId}`,
          ],
          { replaceUrl: true }
        );
      },
      (error) => {
        this._fuseSplashScreenService.hide();
        this.openPopUp({
          data: "error",
          title: "ERROR !",
          description: this.errorhandling(error),
        });
      }
    );
  }

  checkValidation() {
    if (this.isChecked && this.claimsForm.valid) {
      return false;
    }
    return true;
  }

  selectedTypeReference(data) {
    this.initialData = data.value;

    this.claimsForm.controls["typeOfReference"].setValue(data.value);

    if (data.value === "BLNumber") {
      this.claimsForm.controls["bookingNumber"].setValue(null);
      this.claimsForm.controls["bookingNumber"].clearValidators();
      this.claimsForm.controls["bookingNumber"].reset();
      this.claimsForm.controls["blNumber"].markAsTouched();
      this.claimsForm.controls["blNumber"].markAsDirty();
    } else {
      this.claimsForm.controls["blNumber"].setValue(null);
      this.claimsForm.controls["blNumber"].clearValidators();
      this.claimsForm.controls["blNumber"].reset();
      this.claimsForm.controls["bookingNumber"].markAsTouched();
      this.claimsForm.controls["bookingNumber"].markAsDirty();
    }
  }

  blCheckbox(event) {
    if (event.checked) {
      this.isChecked = true;
    } else {
      this.isChecked = false;
    }
  }

  openPopUp(data) {
    return this.dialog.open(PopUpComponent, {
      panelClass: "contact-form-dialog",
      data: {
        data: data.data,
        title: data.title,
        description: data.description,
        showButtons: data?.showButtons,
        proceedButton: data?.proceedButton,
      },
    });
  }

  errorhandling(error) {
    return FuseUtils.handleError(error);
  }

  onClose() {
    if (this.isSuccess) {
      this.hasError = false;
      this.isSuccess = false;
      this.isChecked = false;
      this.claimsForm.controls["containerNumber"].clearValidators();
      this.claimsForm.controls["containerNumber"].reset();
      this.claimsForm.controls["containerNumber"].markAsTouched();
      this.claimsForm.controls["containerNumber"].markAsDirty();
    } else {
      this.matDialogRef.close();
    }
  }
}
