export const environment = {
  globalAggregator: "https://global-aggregator-api.demo.xlog.net",
  globalCMS: "https://global-cms-api.demo.xlog.net",
  clientManagement: "https://client-management-api.demo.xlog.net",
  authManagementAuthentication: "https://auth-service-api.demo.xlog.net/auth",

  uploaderUrl: "https://global-cms-api.demo.xlog.net",

  transactionManagement: "https://transaction-service-api.demo.xlog.net",
  seaFreightService: "https://sea-freight-api.demo.xlog.net",

  truckingService: "https://trucking-service-api.demo.xlog.net",

  trackAndTraceURL: "https://track-and-trace-api.demo.xlog.net",
  trackTraceApiKey: "vfQRM5GqMc1cfycOoqcIY8Lyu2fDTejN2JO4KXsS",

  ssoUrl: "https://sso-middleware-api.demo.xlog.net",
  loginUrl: "https://login.demo.xlog.net/",

  firebase: {
    apiKey: "AIzaSyDpKrQ73GqsM7HAgGTqBUbmBU0q8nR8Vss",
    authDomain: "optimus-realtime.firebaseapp.com",
    databaseURL: "https://optimus-realtime.firebaseio.com",
    projectId: "optimus-realtime",
    storageBucket: "optimus-realtime.appspot.com",
    messagingSenderId: "713358032967",
    appId: "1:713358032967:web:38c2104466d42e1ccaf162",
  },

  production: true,
  hmr: false,

  developerMap: true,
  containerManagement: "https://container-inventory-api.demo.xlog.net",

  isDevelopMode: false,
  urlRedirect: "https://custom-broker.demo.xlog.net",
  googleApiKey: "AIzaSyDnC23xX9YyLwYIbdx4nkegfRH6LTIrcP0",
  redirectionPaynet: "https://uat.mepsfpx.com.my/FPXMain/seller2DReceiver.jsp",
  xlogChat: "https://smalltalkui.chatframe.calicubilly.com",
  blackbox: "https://web.blackboxapp.demo.xlog.net",
  base64: "https://formimage.demo.xlog.net/demo-getBase64FromImageUrl",
  base64Key: "1YpBVknVbR8EGvyjDsEfF2T0Nq6yurfoaNb4YA8D",
  rlvaUrl: "https://sandbox.epayment.dev/01-mode.php",
  callbackReliva: "https://evault.demo.xlog.net/thank-you/reliva",
  updateUrl: "https://api-evault.demo.xlog.net/api/v1/reliva/payment-callback",
  cyWebUrl: "http://localhost:5173",
};
