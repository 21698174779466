import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "environments/environment";

@Injectable({
  providedIn: "root",
})
export class ContactsService {
  constructor(private http: HttpClient) {}

  /**
   * get Contact List
   *
   * @returns {Observable<any>}
   * @memberof ContactsService
   */
  getContacts(payload): Observable<any> {
    return this.http.get<any>(
      `${environment.clientManagement}/clients/api/v1/company/details/preferred-contacts?pageNumber=0&pageSize=`,
      payload
    );
  }

  /**
   * Add Guest Contacts
   *
   * @param {*} payload
   * @returns {Observable<any>}
   * @memberof ContactsService
   */
  addContacts(payload): Observable<any> {
    return this.http.post<any>(
      `${environment.clientManagement}/clients/api/v1/guest/register`,
      payload
    );
  }

  /**
   * View Guest/Register contact details
   *
   * @param {*} userId
   * @returns {Observable<any>}
   * @memberof ContactsService
   */
  getContactDetails(userId): Observable<any> {
    return this.http.get<any>(
      `${environment.clientManagement}/clients/api/v1/preferred-contacts/${userId}/details`
    );
  }

  /**
   * update Contact Details
   *
   * @param {*} payload
   * @returns {Observable<any>}
   * @memberof ContactsService
   */
  updateContactDetails(payload): Observable<any> {
    return this.http.put<any>(
      `${environment.clientManagement}/clients/api/v1/preferred-contacts/guest`,
      payload
    );
  }

  /**
   *
   *
   * @param {*} payload
   * @returns {Observable<any>}
   * @memberof ContactsService
   */
  sendInvites(payload): Observable<any> {
    return this.http.post<any>(
      `${environment.clientManagement}/clients/api/v1/preferred-contacts/send-invite`,
      payload
    );
  }

  /**
   * get details of the sender of the code
   *
   * @param {*} code
   * @returns {Observable<any>}
   * @memberof ContactsService
   */
  checkDetailsViaInviteCode(code): Observable<any> {
    return this.http.get<any>(
      `${environment.clientManagement}/clients/api/v1/preferred-contacts/check-invite/${code}`
    );
  }

  /**
   * Accept code to add registered contacts
   *
   * @param {*} payload
   * @returns {Observable<any>}
   * @memberof ContactsService
   */
  addRegisteredContacts(payload): Observable<any> {
    return this.http.post<any>(
      `${environment.clientManagement}/clients/api/v1/preferred-contacts/accept-invite`,
      payload
    );
  }

  /**
   * Delete single contact
   *
   * @param {*} preferredContactId
   * @returns {Observable<any>}
   * @memberof ContactsService
   */
  deleteContacts(preferredContactId): Observable<any> {
    return this.http.delete<any>(
      `${environment.clientManagement}/clients/api/v1/preferred-contacts/${preferredContactId}`
    );
  }

  getPreferredProviderListing(
    active,
    direction,
    filter,
    pageIndex,
    pageSize
  ): Observable<any> {
    let params = new HttpParams();
    params = params.append("search", filter);
    if (active != null && active != "") {
      if (active == "companyName") {
        active = "CompanyName";
      } else if (active == "country") {
        active = "Country";
      } else if (active == "state") {
        active = "State";
      } else if (active == "Service") {
        active = "Service";
      }
      params = params.append("sortBy", active);
    }
    if (direction != null && direction != null) {
      params = params.append("sortOrder", direction);
    }

    params = params.append("pageNumber", pageIndex);
    params = params.append("pageSize", pageSize);
    return this.http.get<any>(
      `${environment.clientManagement}/clients/api/v1/company/details/preferred-providers/quick-search?`,
      { params: params }
    );
  }

  getCompanyListToAddAsPreferredProvider(filter, search, pageIndex, pageSize) {
    let params = new HttpParams().set("search", `${search}`);

    params = params.append("pageNumber", pageIndex);
    params = params.append("pageSize", pageSize);

    return this.http.get<any>(
      `${environment.clientManagement}/clients/api/v1/providers/quick-search?`,
      { params: params }
    );
  }

  AddCompanyListToAddAsPreferredProvider(payload) {
    return this.http.post<any>(
      `${environment.clientManagement}/clients/api/v1/company/details/preferred-providers`,
      payload
    );
  }

  sendInvitesPreferredProvider(payload): Observable<any> {
    return this.http.post<any>(
      `${environment.clientManagement}/clients/api/v1/preferred-providers/send-invite`,
      payload
    );
  }

  addPreferredProviderViaInviteCode(payload): Observable<any> {
    return this.http.post<any>(
      `${environment.clientManagement}/clients/api/v1/preferred-providers/accept-invite`,
      payload
    );
  }
  checkPreferredProviderDetailsViaInviteCode(code): Observable<any> {
    return this.http.get<any>(
      `${environment.clientManagement}/clients/api/v1/preferred-providers/check-invite/${code}`
    );
  }
  getCompanyListToAddAsPreferredProviderSearch(filter) {
    if (filter == null) {
      filter = "";
    }
    //`
    let params = new HttpParams()
      .set("search", filter.search)
      .set("serviceId", filter.serviceId)
      .set("otherProviderPageNumber", filter.otherProviderPageNumber)
      .set("otherProviderPageSize", filter.otherProviderPageSize)
      .set("otherProviderRecordCount", filter.otherProviderRecordCount)
      .set("preferredProviderPageNumber", filter.preferredProviderPageNumber)
      .set("preferredProviderPageSize", filter.preferredProviderPageSize)
      .set("preferredProviderRecordCount", filter.preferredProviderRecordCount);

    return this.http.get<any>(
      `${environment.clientManagement}/clients/api/v1/providers/quick-search`,
      { params: params }
    );
  }

  /**
   * Delete Preferred Provider Listing
   *
   * @param {*} preferredContactId
   * @returns {Observable<any>}
   * @memberof ContactsService
   */
  deletePreferredProvider(preferredProvider): Observable<any> {
    return this.http.delete<any>(
      `${environment.clientManagement}/clients/api/v1/company/details/preferred-providers/${preferredProvider}`
    );
  }

  getServiceType(): Observable<any> {
    return this.http.get<any>(
      `${environment.globalAggregator}/g/api/v1/service-types`
    );
  }

  getPreferredProviderListingByfilter(
    active,
    direction,
    columnFilterValue,
    filter,
    pageIndex,
    pageSize
  ): Observable<any> {
    let params = new HttpParams();
    if (active != null && active != "") {
      if (active == "companyName") {
        active = "CompanyName";
      } else if (active == "country") {
        active = "Country";
      } else if (active == "state") {
        active = "State";
      } else if (active == "Service") {
        active = "Service";
      }
      params = params.append("sortBy", active);
    }
    if (direction != null && direction != null) {
      params = params.append("sortOrder", direction);
    }
    let companyName, country, state, service;
    companyName = columnFilterValue.companyName;
    country = columnFilterValue.country;
    state = columnFilterValue.state;
    service = columnFilterValue.service.toLowerCase();
    service = service.split(" ");
    if (service.length != null && service.length != 0 && service != "") {
      for (var i = 0, x = service.length; i < x; i++) {
        service[i] = service[i].charAt(0).toUpperCase() + service[i].substr(1);
      }
      service = service.join(" ");
    } else {
      if (service != "") {
        service = service.charAt(0).toUpperCase() + service.slice(1);
      }
    }

    params = params.append("pageNumber", pageIndex);
    params = params.append("pageSize", pageSize);
    return this.http.get<any>(
      `${environment.clientManagement}/clients/api/v1/company/details/preferred-providers/quick-search?search=${filter}&filters=companyName:${companyName},country:${country},state:${state},service:${service}`,
      { params: params }
    );
  }

  getRegisteredDetails(preferredContactId): Observable<any> {
    return this.http.get<any>(
      `${environment.clientManagement}/clients/api/v1/preferred-contacts/${preferredContactId}/details`
    );
  }
}
