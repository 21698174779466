import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BookingService } from 'app/services/booking/booking.service';
import { PopUpComponent } from 'app/layout/components/pop-up/pop-up.component';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { SnackbarComponent } from '@fuse/components/snackbar/snackbar.component';
import { FuseUtils } from '@fuse/utils';
import { MatSnackBar } from '@angular/material/snack-bar';

export interface CompanyInfo {
  imageUrl: string;
  companyName: string;
  country: string;
  state: string;
  isSelected: boolean;
}

@Component({
  selector: 'app-registered-shipper-dialog',
  templateUrl: './registered-shipper-dialog.component.html',
  styleUrls: ['./registered-shipper-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations,
})
export class RegisteredShipperDialogComponent implements OnInit {

  type: any;
  inviteCodeForm: FormGroup;

  senderDetails: any;
  loading: boolean = false;

  companyInfo: CompanyInfo[] = [
    {
      imageUrl:
        "/assets/images/svg/titanic-the-unsinkable-ship.jpg",
      companyName: "CDO Foodsphere",
      country: "Philippines",
      state: "Pasig City",
      isSelected: false,
    }
  ];

  constructor(
    private dialog: MatDialog,
    public matDialogRef: MatDialogRef<RegisteredShipperDialogComponent>,
    private _formBuilder: FormBuilder,
    private bookingService: BookingService,
    @Inject(MAT_DIALOG_DATA) _data: any,
    private _fuseSplashScreenService: FuseSplashScreenService,
    private _snackBar: MatSnackBar
  ) {

    this.matDialogRef.disableClose = true;
    if (_data) {
      this.type = _data.type;
    }

  }

  ngOnInit(): void {
    this.inviteCodeForm = this._formBuilder.group({
      code: ["", [Validators.required, Validators.minLength(5), Validators.maxLength(5)]],
    });
  }

  /**
   *
   *
   * @param {*} data
   * @returns
   * @memberof PreferredProviderComponent
   */
  openPopUp(data) {
    return this.dialog.open(PopUpComponent, {
      panelClass: "contact-form-dialog",
      data: {
        data: data.data,
        title: data.title,
        description: data.description,
        showDelete: data.showDelete,
      },
    });
  }

  getSenderDetails(code) {
    this.loading = true;
    this.bookingService.checkInvite(code).subscribe((res) => {
      if (res) {
        this.loading = false;
        this.senderDetails = res.data.sender;
      }
    }, error => {
      this.loading = false;
      console.warn(error);
      this.openPopUp(
        {
          data: 'error',
          title: 'ERROR !',
          description: error
        }
      );
    })
  }

  ngAfterViewInit() {

    this.inviteCodeForm.get("code").valueChanges.subscribe(code => {

      if (this.inviteCodeForm.get('code').value.length == 5) {
        this.getSenderDetails(this.inviteCodeForm.get('code').value)
      } else {
        this.senderDetails = null;
      }
    })

  }

  addRegisteredContact() {
    this._fuseSplashScreenService.show();
    const data = this.inviteCodeForm.getRawValue();

    const payload = {
      InviteCode: data.code,
    }


    this.bookingService.addRegisteredContact(payload).subscribe((res) => {
      if (res) {
        this._fuseSplashScreenService.hide();
        this._snackBar.openFromComponent(SnackbarComponent, FuseUtils.snackBarConfig(
          "Success!",
          "success",
          'Added Successfully.'
        ))
        this.matDialogRef.close()
      }
    }, error => {
      console.warn(error);
      this._fuseSplashScreenService.hide();
      this.openPopUp(
        {
          data: 'error',
          title: 'ERROR !',
          description: error
        }
      );
    }
    )
  }



}
