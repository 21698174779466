import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Response } from 'app/core/models/response.model';
import { FuseUtils } from '@fuse/utils';

@Injectable({
  providedIn: 'root'
})
export class AccessControlService {

  constructor(
    private http: HttpClient
  ) { }

  /**
   * Get all list of User
   *
   * @returns
   * @memberof AccessControlService
   */
  getUser(): Observable<any> {
    return this.http.get<any>(`${environment.clientManagement}/clients/api/v1/company/users`)
  }

  /**
   * Get All Filtered User
   *
   * @param {*} filter
   * @returns
   * @memberof AccessControlService
   */
  getUserFilteredList(filter): Observable<any> {
    let params = new HttpParams().set('query', filter);
    return this.http.get<any>(`${environment.clientManagement}/clients/api/v1/company/users/filter`, { params: params })
  }

  /**
   * Update User
   *
   * @param {*} payload
   * @param {*} type
   * @returns
   * @memberof AccessControlService
   */
  updateUser(payload, type): Observable<any> {
    return this.http.put<any>(`${environment.clientManagement}/clients/api/v1/user/${type}`, payload)
  }

  /**
   * Update Multiple User
   *
   * @param {*} payload
   * @returns
   * @memberof AccessControlService
   */
  updateMultipleUser(payload, type): Observable<any> {
    return this.http.put<any>(`${environment.clientManagement}/clients/api/v1/user/${type}/multiple`, payload)
  }

  /**
   * Delete User
   *
   * @param {*} userId
   * @returns
   * @memberof AccessControlService
   */
  deleteUser(userId): Observable<any> {
    return this.http.delete<any>(`${environment.clientManagement}/clients/api/v1/user/${userId}`);
  }


  /**
   * View User Information
   *
   * @param {*} userId
   * @returns {Observable<any>}
   * @memberof AccessControlService
   */
  viewUser(userId): Observable<any> {
    return this.http.get<any>(`${environment.clientManagement}/clients/api/v1/user/${userId}`)
  }

  /**
   * Get Company Service By Company ID
   *
   * @param {*} companyId
   * @returns {Observable<any>}
   * @memberof AccessControlService
   */
  getCompanyServiceByCompanyId(companyId): Observable<any> {
    return this.http.get<any>(`${environment.clientManagement}/clients/api/v1/company/services/${companyId}`)
  }

  /**
   * Get Company service Role
   *
   * @param {*} companyServiceId
   * @returns {Observable<any>}
   * @memberof AccessControlService
   */
  getCompanyServiceRoleByCompanyServiceId(companyServiceId): Observable<any> {
    return this.http.get<any>(`${environment.clientManagement}/clients/api/v1/company/services/role/${companyServiceId}`)
  }

  /**
   * Adds User
   *
   * @param {*} payload
   * @returns {Observable<any>}
   * @memberof AccessControlService
   */
  addUser(payload): Observable<any> {
    return this.http.post<any>(`${environment.clientManagement}/clients/api/v1/user`, payload)
  }

  /**
   * Deletes Multiple User
   *
   * @param {*} payload
   * @returns {Observable<any>}
   * @memberof AccessControlService
   */
  deleteMultipleUser(payload): Observable<any> {
    return this.http.put<any>(`${environment.clientManagement}/clients/api/v1/user/delete/multiple`, payload)
  }

  /**
   * Updates User Information
   *
   * @param {*} payload
   * @returns {Observable<any>}
   * @memberof AccessControlService
   */
  updateUserInformation(payload: any): Observable<any> {
    return this.http.put<any>(`${environment.clientManagement}/clients/api/v1/user`, payload)
  }

  /**
   * Reset Password
   *
   * @param {*} payload
   * @returns {Observable<any>}
   * @memberof AccessControlService
   */
  resetPasswordEmail(payload): Observable<any> {
    return this.http.post<any>(`${environment.authManagementAuthentication}/api/v1/Auth/forgot-password`, payload)
  }

  /**
   * Get Access Group Listing with Company ID
   *
   * @param {string} companyId
   * @param {any} [status]
   * @returns {Observable<any>}
   * @memberof AccessControlService
   */
  getAccessGroupListing(companyId: string, status?: any): Observable<any> {
    let params = new HttpParams();
    if (status >= 0) {
      params = params.append('status', status);
    }
    return this.http.get<any>(`${environment.clientManagement}/clients/api/v1/company/services/role/company/${companyId}`, { params: params })
  }

  /**
   * View Group
   *
   * @param {string} companyServiceRoleId
   * @returns {Observable<any>}
   * @memberof AccessControlService
   */
  viewGroup(companyServiceRoleId: string): Observable<any> {
    return this.http.get<any>(`${environment.clientManagement}/clients/api/v1/company-service-role/${companyServiceRoleId}`)
  }

  /**
   * Update Group
   *
   * @param {*} payload
   * @param {string} companyServiceRoleId
   * @returns {Observable<any>}
   * @memberof AccessControlService
   */
  updateGroup(payload: any, companyServiceRoleId: string): Observable<any> {
    return this.http.put<any>(`${environment.clientManagement}/clients/api/v1/company-service-role/${companyServiceRoleId}`, payload)
  }

  /**
   *
   *
   * @param {*} payload
   * @returns {Observable<any>}
   * @memberof AccessControlService
   */
  addGroup(payload: any): Observable<any> {
    return this.http.post<any>(`${environment.clientManagement}/clients/api/v1/company-service-group-user`, payload)
  }

  /**
   * Get users to be loaded in Create Group and Update
   *
   * @param {*} payload
   * @param {string} [fullName]
   * @returns
   * @memberof AccessControlService
   */
  getUserList(payload: any, fullName?: string) {
    let params =
      new HttpParams().set(payload && payload.type, payload && payload.value)
        .set(payload && payload.typeTwo, payload && payload.serviceId);
    if (fullName) {
      params = params.append('fullName', fullName)
    }
    return this.http.get<any>(`${environment.clientManagement}/clients/api/v1/company-service-user/${payload && payload.companyId}`, { params: params })
  }

  /**
   * Download file
   *
   * @param {*} [parameter]
   * @returns {Observable<any>}
   * @memberof AccessControlService
   */
  downloadFile(parameter?: any): Observable<any> {
    let params =
      new HttpParams();

    if (parameter) {
      params = params.append('query', parameter);
    }
    return this.http.get<any>(`${environment.clientManagement}/clients/api/v1/company/users/download`, { params: params, responseType: 'blob' as 'json' })
  }

  /**
   * Get Permissions
   *
   * @param {*} companyService
   * @returns {Observable<any>}
   * @memberof AccessControlService
   */
  getPermissions(companyService: any): Observable<any> {
    return this.http.get<any>(`${environment.globalAggregator}/g/api/v1/dashboard/menu/${companyService}/all`)
  }

  /**
   * Get all Resource Groups
   *
   * @param {string} companyServiceRoleGuid
   * @returns {Observable<any>}
   * @memberof AccessControlService
   */
  getResourceGroups(companyServiceRoleGuid: string): Observable<any> {
    let params = new HttpParams().set('companyServiceRoleGuid', companyServiceRoleGuid);
    return this.http.get<any>(`${environment.clientManagement}/clients/api/v1/companygroupresource`, { params: params })
  }

  /**
   * Updates or Delete Group
   *
   * @param {*} payload
   * @returns {Observable<any>}
   * @memberof AccessControlService
   */
  updateDeleteGroup(payload: any, type: string): Observable<any> {
    if (type === 'delete') {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
        body: payload
      }
      return this.http.delete<any>(`${environment.clientManagement}/clients/api/v1/company-service-role`, httpOptions)
    }
    return this.http.put<any>(`${environment.clientManagement}/clients/api/v1/company-service-role`, payload)
  }

  /**
   * Process data received from BE Permission
   *
   * @param {*} permissions
   * @returns {*}
   * @memberof AccessControlService
   */
  processPermission(permissions: any): any {
    return permissions.map((permission) => {
      let cbm;
      let cmm;
      let mg;
      let pcmm;
      let pmg;

      if (permission && permission.clientMenuModules && permission.clientMenuModules.length) {
        pcmm = permission.clientMenuModules.map((parentClientMenuModule) => {
          if (parentClientMenuModule && parentClientMenuModule.module && parentClientMenuModule.module.moduleGroups.length) {
            pmg = parentClientMenuModule.module.moduleGroups.map((moduleGroup) => {
              return {
                ...moduleGroup,
                isChecked: false
              }
            })
            parentClientMenuModule.module.moduleGroups = pmg
          }

          return {
            ...parentClientMenuModule
          }
        })
      }

      if (permission && permission.clientSubMenus && permission.clientSubMenus.length) {
        cbm = permission.clientSubMenus.map((clientSubMenu) => {
          if (clientSubMenu && clientSubMenu.clientMenuModules.length) {
            cmm = clientSubMenu.clientMenuModules.map((clientMenuModule) => {
              if (clientMenuModule && clientMenuModule.module && clientMenuModule.module.moduleGroups.length) {
                mg = clientMenuModule.module.moduleGroups.map((moduleGroup) => {
                  return {
                    ...moduleGroup,
                    isChecked: false
                  }
                })
                clientMenuModule.module.moduleGroups = mg
              }

              return {
                ...clientMenuModule,
              }
            })
          }

          return {
            ...clientSubMenu,
            clientMenuModules: cmm
          }
        })
      }
      return {
        ...permission,
        clientSubMenus: cbm || [],
        clientMenuModules: pcmm || []
      }
    })
  }

  /**
   * Matches the permission and saved permission
   *
   * @param {*} permissions
   * @param {*} resource
   * @returns {*}
   * @memberof AccessControlService
   */
  matchPermission(permissions: any, resource: any): any {
    return permissions.map((permission) => {
      let cbm;
      let cmm;
      let mg;
      let pcmm;
      let pmg;

      if (permission && permission.clientMenuModules && permission.clientMenuModules.length) {
        pcmm = permission.clientMenuModules.map((parentClientMenuModule) => {
          if (parentClientMenuModule && parentClientMenuModule.module && parentClientMenuModule.module.moduleGroups.length) {
            pmg = parentClientMenuModule.module.moduleGroups.map((moduleGroup) => {
              const foundModuleGroup = resource.find((e) => e.groupResourceId === moduleGroup.groupResources[0]?.groupResourceId)
              return {
                ...moduleGroup,
                isChecked: (foundModuleGroup) ? true : false
              }
            })
            parentClientMenuModule.module.moduleGroups = pmg
          }

          return {
            ...parentClientMenuModule
          }
        })
      }

      if (permission && permission.clientSubMenus && permission.clientSubMenus.length) {
        cbm = permission.clientSubMenus.map((clientSubMenu) => {
          if (clientSubMenu && clientSubMenu.clientMenuModules.length) {
            cmm = clientSubMenu.clientMenuModules.map((clientMenuModule) => {
              if (clientMenuModule && clientMenuModule.module && clientMenuModule.module.moduleGroups.length) {
                mg = clientMenuModule.module.moduleGroups.map((moduleGroup) => {
                  const foundModuleGroup = resource.find((e) => e.groupResourceId === moduleGroup.groupResources[0]?.groupResourceId)
                  return {
                    ...moduleGroup,
                    isChecked: (foundModuleGroup) ? true : false
                  }
                })
                clientMenuModule.module.moduleGroups = mg
              }

              return {
                ...clientMenuModule,
              }
            })
          }

          return {
            ...clientSubMenu,
            clientMenuModules: cmm
          }
        })
      }
      return {
        ...permission,
        clientSubMenus: cbm || [],
        clientMenuModules: pcmm || []
      }
    })
  }

  /**
   *
   *
   * @param {string} type
   * @param {string} [companyServiceGuid]
   * @param {string} [keyGuid]
   * @returns {Observable<any>}
   * @memberof AccessControlService
   */
  getGroupAuditLogs(type: string, companyServiceGuid?: string, keyGuid?: string): Observable<Response> {
    let params = new HttpParams().set('type', type)

    if (companyServiceGuid !== '') {
      params = params.append('companyServiceGuid', companyServiceGuid)
    }

    if (keyGuid !== '') {
      params = params.append('keyGuid', keyGuid)
    }

    return this.http.get<Response>(`${environment.clientManagement}/clients/api/v1/audit-logs`, { params: params })
  }

  /**
   * Get Menu Permission
   *
   * @param {string} username
   * @returns {Observable<Response>}
   * @memberof AccessControlService
   */
  getMenuPermissions(username: string): Observable<Response> {
    return this.http.get<Response>(`${environment.clientManagement}/clients/api/v1/companygroupresource/authorization/${username}`)
  }

  getAuditLogs(company): Observable<any> {
    return this.http.get<any>(`${environment.clientManagement}/clients/api/v1/logs/User/${company}`)
  }

  getUserViewLogs(auditLogId): Observable<any> {
    return this.http.get<any>(`${environment.clientManagement}/clients/api/v1/logs/details/${auditLogId}`)
  }

  getRolePermissions(companyServiceRoleId: string): Observable<Response> {
    return this.http.get<Response>(`${environment.clientManagement}/clients/api/v1/company-service-role/${companyServiceRoleId}`)
  }


  updateRolePermission(companyServiceUserId: string, payload): Observable<Response> {
    return this.http.put<Response>(`${environment.clientManagement}/clients/api/v1/company-service-user/${companyServiceUserId}`, payload)
  }

  downloadGroupLisitingExcel(companyServiceId: any): Observable<any> {
    return this.http.get<any>(`${environment.clientManagement}/clients/api/v1/company/services/role/${companyServiceId}/download`, {
      responseType: 'blob' as 'json'
    });
  }

  downloadGroupLisitingCsv(companyServiceId: any): Observable<any> {
    let headers = new HttpHeaders({
      Accept: 'text/csv',
    });
    return this.http.get<any>(`${environment.clientManagement}/clients/api/v1/company/services/role/${companyServiceId}/download?type=csv`, {
      headers,
      responseType: 'text' as 'json'
    });
  }

  downloadGroupAuditLogs(data: any, companyServiceId: any): Observable<any> {
    return this.http.get<any>(`${environment.clientManagement}/clients/api/v1/audit-logs/acm-group/download?type=CompanyServiceRole&${data}=${companyServiceId}`, {
      responseType: 'blob' as 'json'
    });
  }

  downloadUserAuditLogLisitingExcel(userId, payload): Observable<any> {
    let headers = new HttpHeaders({
      Accept: 'text/csv',
    });
    return this.http.get<any>(`${environment.clientManagement}/clients/api/v2/users/${userId}/logs/export`, {
      params: FuseUtils.FormatQuery(payload),
      headers,
      responseType: 'blob' as 'json'
    })
  }

  addRole(payload: any): Observable<any> {
    return this.http.post<any>(`${environment.clientManagement}/clients/api/v1/company-service-user`, payload)
  }

}
