import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { environment } from "environments/environment";
import { Observable, of } from "rxjs";
import { Booking } from "app/core/models/booking.model";
import { Response } from "app/core/models/response.model";
import { env } from "process";

@Injectable({
  providedIn: "root",
})
export class BookingService {
  constructor(private http: HttpClient) { }

  /* Booking Request CRUD */

  createBookingRequest(companyId, payload): Observable<any> {
    return this.http.post<any>(
      `${environment.transactionManagement}/otm/api/v1/transactions/sea-freight`,
      payload
    );
  }

  createReBookingRequest(companyId, payload): Observable<any> {
    return this.http.put<any>(
      `${environment.transactionManagement}/otm/api/v1/transactions/sea-freight/complete-rebooking/${payload.transactionGuid}`,
      payload
    );
  }

  getBookingRequestById(id, status): Observable<any> {
    let params = new HttpParams();
    if (status && status !== "All") {
      params = params.append("status", status);
    }
    return this.http.get<any>(
      `${environment.transactionManagement}/otm/api/v1/transactions/sea-freight/${id}`,
      {
        params: params,
      }
    );
  }

  getAllBookingRequest(bookingPartyId: any, payload: any): Observable<any> {
    let params = new HttpParams()
      .set("query", payload.query)
      .set("pageNumber", payload.page + 1)
      .set("pageSize", payload.pageSize)
      .set("orderBy", payload.orderBy)
      .set("timezoneOffset", payload.timezoneOffset);

    return this.http.get<any>(
      `${environment.transactionManagement}/otm/api/v1/transactions/booking-party/${bookingPartyId}`,
      { params: params }
    );
  }

  getAllBookingRequestTrucking(
    bookingPartyId: any,
    payload: any
  ): Observable<any> {
    let params = new HttpParams()
      .set("query", payload.query)
      .set("pageNumber", payload.page + 1)
      .set("pageSize", payload.pageSize)
      .set("orderBy", payload.orderBy);

    return this.http.get<any>(
      `${environment.transactionManagement}/otm/api/v1/transactions/trucking/booking-party/${bookingPartyId}`,
      { params: params }
    );
  }
  upcomingRecommendation(payload: any, shipmentType) {
    return this.http.post<any>(
      `${environment.seaFreightService}/sfs/api/v1/sea-freight/services-and-rates/outbound-recommendations?shipmentType=${shipmentType}&otherSchedule=true`,
      payload
    );
  }
  searchRecommendation(payload: any, shipmentType): Observable<any> {
    return this.http.post<any>(
      `${environment.seaFreightService}/sfs/api/v1/sea-freight/services-and-rates/outbound-recommendations?shipmentType=${shipmentType}`,
      payload
    );
  }

  // getPorts(): Observable<any> {
  //   return this.http.get<any>(`${environment.cmsUrl}/global/cms/api/v1/port`);
  // }

  /* Get Commodities */
  // getAllCommodities(): Observable<any> {
  //   return this.http.get<any>(`${environment.apiUrl}/g/api/v1/commodity`);
  // }

  // getAllCommoditiesByPage(pageNum, pageSize, records): Observable<any> {
  //   return this.http.get<any>(
  //     `${environment.apiUrl}/g/api/v1/commodity?pageNumber=${pageNum}&pageSize=${pageSize}&recordCount=${records}`
  //   );
  // }

  /* Get guest shipper */
  getAllGuestShipperByCompanyId(id): Observable<any> {
    return this.http.get<any>(
      `${environment.seaFreightService}/sfs/api/v1/guest/${id}/all`
    );
  }

  getAllGuestShipperByCompanyIdAndType(id, type): Observable<any> {
    return this.http.get<any>(
      `${environment.seaFreightService}/sfs/api/v1/guest/${id}/guests/${type}`
    );
  }

  getAllGuestShipperById(id): Observable<any> {
    return this.http.get<any>(
      `${environment.seaFreightService}/sfs/api/v1/guest/${id}`
    );
  }

  addGuestShipper(payload): Observable<any> {
    return this.http.post<any>(
      `${environment.seaFreightService}/sfs/api/v1/guest`,
      payload
    );
  }

  /* Get packaging types */
  getPackagingTypes(): Observable<any> {
    return this.http.get<any>(
      `${environment.globalAggregator}/g/api/v1/packaging-type`
    );
  }

  /* Get load types */
  getLoadTypes(): Observable<any> {
    return this.http.get<any>(
      `${environment.globalAggregator}/g/api/v1/load-type`
    );
  }

  /* Get container types*/
  getContainerTypes(): Observable<any> {
    return this.http.get<any>(
      `${environment.globalAggregator}/g/api/v1/container-type`
    );
  }

  /* Get container types*/
  getContainerSizesByType(id): Observable<any> {
    return this.http.get<any>(
      `${environment.globalAggregator}/g/api/v1/container-type/${id}/sizes`
    );
  }

  /* Get Shipment types*/
  getShipmentTypes(): Observable<any> {
    return this.http.get<any>(
      `${environment.seaFreightService}/sfs/api/v1/utilities/shipmentTypes`
    );
  }

  /* Get container types*/
  getServiceTypes(id): Observable<any> {
    return this.http.get<any>(
      `${environment.globalAggregator}/g/api/v1/booking/services/${id}`
    );
  }

  getUnLoCodeByAddress(query) {
    return this.http.get<any>(
      `${environment.globalAggregator}/g/api/v1/unlocode/filter?query=${query}`
    );
  }

  getRouteInstructions() {
    const instruction = this.http
      .get<any>(`${environment.truckingService}/oat/api/v1/RouteInstructions
    `);
    return instruction;
  }

  reccomedationRoutes(data, instruction = []) {
    let reccomendedRoute;
    switch (data) {
      case 0: {
        reccomendedRoute = instruction.find((_ins) => _ins.id === 5);
        break;
      }

      case 1: {
        reccomendedRoute = instruction.find((_ins) => _ins.id === 0);
        break;
      }
      case 2: {
        reccomendedRoute = reccomendedRoute = instruction.find(
          (_ins) => _ins.id === 7
        );
        break;
      }
      case 3: {
        reccomendedRoute = reccomendedRoute = instruction.find(
          (_ins) => _ins.id === 4
        );
        break;
      }

      case 4: {
        reccomendedRoute = reccomendedRoute = instruction.find(
          (_ins) => _ins.id === 1
        );
        break;
      }
      case 5: {
        reccomendedRoute = reccomendedRoute = instruction.find(
          (_ins) => _ins.id === 2
        );
        break;
      }
      case 6: {
        reccomendedRoute = reccomendedRoute = instruction.find(
          (_ins) => _ins.id === 3
        );
        break;
      }
      case 7: {
        reccomendedRoute = reccomendedRoute = instruction.find(
          (_ins) => _ins.id === 6
        );
        break;
      }
      case 8: {
        reccomendedRoute = reccomendedRoute = instruction.find(
          (_ins) => _ins.id === 0
        );
        break;
      }
    }
    return reccomendedRoute;
  }

  getDangerousCargo() {
    return this.http.get<any>(
      `${environment.globalAggregator}/g/api/v1/dangerous-cargo/filter`
    );
  }

  /**
   *
   * Listing used in Shipper, Consignee, Notify Party (outbound)
   * @param {*} payload
   * @returns {Observable<any>}
   * @memberof BookingService
   */
  getListingContacts(filteredvalues, pageNumber, payload): Observable<any> {
    return this.http.get<any>(
      `${environment.clientManagement}/clients/api/v1/company/details/preferred-contacts?search=${filteredvalues}&pageNumber=${pageNumber}&pageSize=${payload}`,
      payload
    );
  }
  /**
   * Get Unit of measurement List
   *
   * @param {string} type
   * @returns {Observable<Response>}
   * @memberof BookingService
   */
  getUnitOfMeasurement(type: string): Observable<Response> {
    let params = new HttpParams().set("type", type);
    return this.http.get<any>(`${environment.globalAggregator}/g/api/v1/uom`, {
      params: params,
    });
  }

  /**
   *
   * Add Guest Contact in the Listing used in Shipper, Consignee, Notify Party
   * @param {*} payload
   * @returns {Observable<any>}
   * @memberof BookingService
   */
  addContacts(payload): Observable<any> {
    return this.http.post<any>(
      `${environment.globalAggregator}/clients/api/v1/guest/register`,
      payload
    );
  }

  /**
   *
   * Add Registered via Invite Code
   * @param {*} code
   * @returns {Observable<any>}
   * @memberof BookingService
   */
  checkInvite(code): Observable<any> {
    return this.http.get<any>(
      `${environment.clientManagement}/clients/api/v1/preferred-contacts/check-invite/${code}`
    );
  }
  /* Health Check CRUD */
  // getAllHealthCheck(): Observable<any> {
  //   return this.http.get<any>(`${environment.azureUrl}/sfs/api/v1/healthcheck`);
  // }

  /* Health Check CRUD */
  getPorts(): Observable<any> {
    return this.http.get<any>(
      `${environment.globalCMS}/global/cms/api/v1/port?pageSize=5000`
    );
  }

  /* Get Commodities */
  getAllCommodities(): Observable<any> {
    return this.http.get<any>(
      `${environment.globalAggregator}/g/api/v1/commodity`
    );
  }

  getAllCommoditiesByPage(pageNum, pageSize, records): Observable<any> {
    return this.http.get<any>(
      `${environment.globalAggregator}/g/api/v1/commodity?pageNumber=${pageNum}&pageSize=${pageSize}&recordCount=${records}`
    );
  }

  getAllCommoditiesByFilter(
    pageNum,
    pageSize,
    filter,
    companyId = "NONCMA"
  ): Observable<any> {
    return this.http.get<any>(
      `${environment.globalAggregator}/g/api/v1/commodity/quick-search?pageNumber=${pageNum}&pageSize=${pageSize}&search=${filter}&companyId=${companyId}`
    );
  }

  getAllCommoditiesByPageTrucking(
    query,
    pageNum,
    pageSize,
    records
  ): Observable<any> {
    return this.http.get<any>(
      `${environment.globalAggregator}/g/api/v1/commodity/filter?query=CommodityName:${query}&pageNumber=${pageNum}&pageSize=${pageSize}&recordCount=${records}`
    );
  }

  /**
   *
   * Add Registered Contact in the Listing used in Shipper, Consignee, Notify Party
   * @param {*} payload
   * @returns {Observable<any>}
   * @memberof BookingService
   */
  addRegisteredContact(payload): Observable<any> {
    return this.http.post<any>(
      `${environment.clientManagement}/clients/api/v1/preferred-contacts/accept-invite`,
      payload
    );
  }

  /**
   * Download File
   *
   * @param {*} payload
   * @param {string} type
   * @return {*}  {Observable<any>}
   * @memberof BookingService
   */
  downloadFile(payload: any, type: string, shipperId): Observable<any> {
    let params = new HttpParams()
      .set("search", payload.searchString)
      .set("page", payload.page)
      .set("filter", payload.filter)
      .set("shipperId", shipperId)
      .set("sort", payload.sort)
      .set("sortOrder", payload.sortOrder)
      .set("pageSize", payload.totalRecords);

    let headers = new HttpHeaders({
      Accept: type === "csv" ? "text/csv" : "",
    });

    let responseType = `${type === "csv" ? "text" : "blob"}` as "json";

    return this.http.get<any>(
      `${environment.transactionManagement}/otm/api/v1/transactions/file/booking-party/${type}`,
      { params: params, headers, responseType: responseType }
    );
  }

  downloadFileListing(
    payload: any,
    type: string,
    shipperId,
    module
  ): Observable<any> {
    let params = new HttpParams()
      .set("bookingPartyId", shipperId)
      .set("query", payload.query)
      .set("pageNumber", payload.page + 1)
      .set("pageSize", payload.pageSize)
      .set("orderBy", payload.orderBy);

    let headers = new HttpHeaders({
      Accept: type === "csv" ? "text/csv" : "",
    });

    let responseType = `${type === "csv" ? "text" : "blob"}` as "json";

    switch (module) {
      case "bookingReservation":
        return this.http.get<any>(
          `${environment.transactionManagement}/otm/api/v1/transactions/file/booking-party/${type}`,
          { params: params, headers, responseType: responseType }
        );
      case "trucking":
        return this.http.get<any>(
          `${environment.transactionManagement}/otm/api/v1/transactions/trucking/file/booking-party/${type}`,
          { params: params, headers, responseType: responseType }
        );
    }
  }

  getRecommendedTrucking(payload, isBacktoBack): Observable<any> {
    return this.http.post<any>(
      `${environment.truckingService}/oat/api/v1/GetRecommendationRouteContainers?IsAllowBackToBack=${isBacktoBack}
      `,
      payload
    );
  }

  /* Get Service Type */
  getServiceTypeTrucking(): Observable<any> {
    return this.http.get<any>(
      `${environment.truckingService}/oat/api/v1/GetServiceType`
    );
  }

  /* Get Load Type */
  getLoadTypeTrucking(): Observable<any> {
    return this.http.get<any>(
      `${environment.truckingService}/oat/api/v1/GetLoadType`
    );
  }

  /* Get Commodities */
  getRecommendationRouteContainers(payload, isBacktoBack): Observable<any> {
    return this.http.post<any>(
      `${environment.truckingService}/oat/api/v1/GetRecommendationRouteContainers?IsShowAll=true&IsAllowBackToBack=${isBacktoBack}`,
      payload
    );
  }

  getRecommendationRouteContainersPerTrucker(
    payload,
    isBacktoBack,
    truckingCompanyId
  ): Observable<any> {
    return this.http.post<any>(
      `${environment.truckingService}/oat/api/v1/GetRecommendationRouteContainers-per-trucker?TruckingCompanyId=${truckingCompanyId}&IsShowAll=true&IsAllowBackToBack=${isBacktoBack}`,
      payload
    );
  }

  bookingPortalTransaction(payload): Observable<any> {
    return this.http.post<any>(
      `${environment.transactionManagement}/otm/api/v1/transactions/trucking`,
      payload
    );
  }

  getPaymentType(): Observable<any> {
    return this.http.get<any>(
      `${environment.truckingService}/oat/api/v1/GetPaymentType`
    );
  }

  getPaymentTerms(): Observable<any> {
    return this.http.get<any>(
      `${environment.truckingService}/oat/api/v1/GetPaymentTerms`
    );
  }

  /**
   * Get Available Fleet
   *
   * @param {*} payload
   * @return {*}  {Observable<any>}
   * @memberof BookingService
   */
  getAvailableSchedules(payload: any): Observable<any> {
    return this.http.post<any>(
      `${environment.truckingService}/oat/api/v1/GetFleetAvailability`,
      payload
    );
  }

  /**
   * Get Service Provider List
   *
   * @param {*} serviceId
   * @param {*} page
   * @param {*} row
   * @param {*} search
   * @return {*}  {Observable<any>}
   * @memberof BookingService
   */
  getServiceProviderList(serviceId, page, row, search): Observable<any> {
    let params = new HttpParams();
    params = params.append("search", search);
    return this.http.get<any>(
      `${environment.clientManagement}/accreditation/api/v1/provider/list/${serviceId}?page=${page}&rows=${row}`,
      { params: params }
    );
  }

  /**
   * Used in Booking Portal Inbound
   *
   * @param {*} companyId
   * @param {*} portId
   * @return {*}  {Observable<any>}
   * @memberof BookingService
   */
  getShippingAgency(companyId, portId): Observable<any> {
    return this.http.get<any>(
      `${environment.clientManagement}/accreditation/api/v1/customer/accredited/individual/portOfResponsibility/${companyId}/${portId}`
    );
  }

  getPostalCode(cityId): Observable<any> {
    return this.http.get<any>(
      `${environment.globalCMS}/global/cms/api/v2/postal/${cityId}/postal-codes`
    );
  }

  getUnLoCodeList(countryName, location, division): Observable<any> {
    return this.http.get<any>(
      `${environment.globalAggregator}/g/api/v1/unlocode/filter?query=Country:${countryName},Division:${division},Location:${location}`
    );
  }

  /**
   *
   * Listing used in Shipper, Consignee, Notify Party (inbound)
   * @param {*} payload
   * @returns {Observable<any>}
   * @memberof BookingService
   */
  getContactsInbound(
    search,
    pageNumber,
    pageSize,
    serviceRoleGroup
  ): Observable<any> {
    return this.http.get<any>(
      `${environment.clientManagement}/clients/api/v1/preferred-contacts/quick-search?search=${search}&pageNumber=${pageNumber}&pageSize=${pageSize}&serviceRoleGroup=${serviceRoleGroup}`
    );
  }

  getContactsShippingInstruction(
    search,
    pageNumber,
    pageSize,
    serviceRoleGroup
  ): Observable<any> {
    return this.http.get<any>(
      `${environment.clientManagement}/clients/api/v1/preferred-contacts?search=${search}&pageNumber=${pageNumber}&pageSize=${pageSize}&serviceRoleGroup=${serviceRoleGroup}`
    );
  }

  /**
   *
   * Packaging Group
   * @param {*} payload
   * @returns {Observable<any>}
   * @memberof BookingService
   */
  getPackage(): Observable<any> {
    return this.http.get<any>(
      `${environment.seaFreightService}/sfs/api/v1/sea-freight/dangerouscargo/packagingGroup`
    );
  }

  /**
   *
   * IMO Class Number
   * @param {*} payload
   * @returns {Observable<any>}
   * @memberof BookingService
   */
  getIMOClassName(): Observable<any> {
    return this.http.get<any>(
      `${environment.seaFreightService}/sfs/api/v1/sea-freight/dangerouscargo/classNumber`
    );
  }

  /**
   *
   * Excepted Quantity
   * @param {*} payload
   * @returns {Observable<any>}
   * @memberof BookingService
   */
  getExeQuantity(): Observable<any> {
    return this.http.get<any>(
      `${environment.seaFreightService}/sfs/api/v1/sea-freight/dangerouscargo/exceptedQty`
    );
  }

  /**
   *
   * UN Packaging Code
   * @param {*} payload
   * @returns {Observable<any>}
   * @memberof BookingService
   */
  getUNPackagingCode(): Observable<any> {
    return this.http.get<any>(
      `${environment.seaFreightService}/sfs/api/v1/sea-freight/dangerouscargo/UNPackagingCode`
    );
  }

  /**
   *
   * Unit Measurement
   * @param {*} payload
   * @returns {Observable<any>}
   * @memberof BookingService
   */
  getUnitMeasurement(): Observable<any> {
    return this.http.get<any>(
      `${environment.seaFreightService}/sfs/api/v1/sea-freight/dangerouscargo/UnitMeasurement`
    );
  }

  /**
   *
   * Search UN NUmber
   * @param {*} payload
   * @returns {Observable<any>}
   * @memberof BookingService
   */
  getSearchUnNumber(payload: any): Observable<any> {
    let params = new HttpParams();
    params = params.append("pageNumber", payload.pageNumber);
    params = params.append("rowPerPage", payload.pageSize);
    params = params.append("UNNumber", payload.searchString);

    return this.http.get<any>(
      `${environment.seaFreightService}/sfs/api/v1/sea-freight/dangerouscargo/SearchHazardousMaterial`,
      { params: params }
    );
  }

  postSFDangerousCargo(payload: any): Observable<any> {
    return this.http.post<any>(
      `${environment.seaFreightService}/sfs/api/v1/sea-freight/dangerouscargo/AddSeaFreightDangerousCargo`,
      payload
    );
  }
  /**
   * Create new sea freight transaction reservation (Inbound)
   *
   * @param {*} payload
   * @return {*}  {Observable<any>}
   * @memberof BookingService
   */
  getTransactionReservationInbound(payload: any): Observable<any> {
    return this.http.post<any>(
      `${environment.transactionManagement}/otm/api/v1/transactions/sea-freight/inbound`,
      payload
    );
  }

  getDashboardScoreCards(shipperId, query): Observable<any> {
    let params = new HttpParams().set("id", shipperId).set("column", query);

    return this.http.get<any>(
      `${environment.transactionManagement}/otm/api/v1/transactions/dashboard-score-card`,
      { params: params }
    );
  }

  /**
   *
   *
   * @param {*} id
   * @param {*} number
   * @param {*} size
   * @return {*}  {Observable<any>}
   * @memberof BookingService
   */
  getDraft(id, number, size, filteredValue, sort): Observable<any> {
    let numbernow = number + 1;
    const params = [];

    for (const key in filteredValue) {
      if (filteredValue[key]) {
        params.push(`${key}:${filteredValue[key]}`);
      }
    }

    const url = "&query=" + params.join(",");

    return this.http.get<any>(
      `${environment.transactionManagement}/otm/api/v1/transactions/booking-reservation-draft/user/${id}?pageNumber=${numbernow}&pageSize=${size}&orderBy=${sort}&query=` +
      params.join(",")
    );
  }

  saveDraftAsTemplate(payload): Observable<any> {
    return this.http.post<any>(
      `${environment.transactionManagement}/otm/api/v1/transactions/booking-reservation-template`,
      payload
    );
  }

  getTemplate(id, number, size, filteredValue, sort): Observable<any> {
    number = number + 1;
    const params = [];
    for (const key in filteredValue) {
      if (filteredValue[key]) {
        params.push(`${key}:${filteredValue[key]}`);
      }
    }
    const url = "&query=" + params.join(",");
    return this.http.get<any>(
      `${environment.transactionManagement}/otm/api/v1/transactions/booking-reservation-template/company/${id}?pageNumber=${number}&pageSize=${size}&orderBy=${sort}&query=` +
      params.join(",")
    );
  }

  deleteTemplatet(id): Observable<any> {
    return this.http.post<any>(
      `${environment.transactionManagement}/otm/api/v1/transactions/booking-reservation-template/delete/${id}`,
      []
    );
  }

  editTemplatet(payload): Observable<any> {
    return this.http.put<any>(
      `${environment.transactionManagement}/otm/api/v1/transactions/booking-reservation-template`,
      payload
    );
  }

  /**
   * Get Insurance Recommendation
   * based on Container Payload
   *
   * @param {*} payload
   * @return {*}  {Observable<any>}
   * @memberof BookingService
   */
  insuranceRecommendation(payload, countryCode): Observable<any> {
    return this.http.post<any>(
      `${environment.transactionManagement}/otm/api/v1/transactions/insurance/container-insurance/recommendation?countryCode=${countryCode}`,
      payload
    );
  }

  manageInvoice(reservationId: string, shippingLine, serviceId, payload): Observable<any> {
    return this.http.post<any>(
      `${environment.seaFreightService}/sfs/api/v1/sea-freight/shipping-line/${shippingLine}/${reservationId}/${serviceId}/release-origin-invoice`,
      payload
    );
  }

  validationPaymentOrigin(reservationId: string, shippingLine, serviceId, payload): Observable<any> {
    return this.http.post<any>(
      `${environment.seaFreightService}/sfs/api/v1/sea-freight/shipping-line/${shippingLine}/${reservationId}/${serviceId}/validate-origin-payment`,
      payload
    );
  }

  // Seafreight track n trace APIs //

  getSFTracknTraceData(reservationId, filter): Observable<any> {
    return this.http.get<any>(
      `${environment.seaFreightService}/sfs/api/v1/transactions/sea-freight/${reservationId}/track-trace?type=${filter.type}`
      // ,
      // { params: params}
    );
  }

  getSeaFreightServiceLevel(
    bookingPartyId,
    filteredValue,
    orderBy,
    pageNumber,
    pageSize
  ): Observable<any> {
    pageNumber++;
    const params = [];
    let query: boolean = false;
    for (const key in filteredValue) {
      if (filteredValue[key]) {
        query = true;
        params.push(`${key}:${filteredValue[key]}`);
      }
    }
    const url = query ? `query=` + params.join(",") + "&" : "";
    return this.http.get<any>(
      `${environment.transactionManagement}/otm/api/v1/transactions/sea-freight/booking-party/${bookingPartyId}/service-level?${url}${orderBy}pageNumber=${pageNumber}&pageSize=${pageSize}`
    );
  }

  downloadSeaFreightListing(
    payload: any,
    type: string,
    bookingPartyId
  ): Observable<any> {
    payload.pageNumber++;
    const params = [];
    let query: boolean = false;
    let filteredValue = payload?.query;
    for (const key in filteredValue) {
      if (filteredValue[key]) {
        query = true;
        params.push(`${key}:${filteredValue[key]}`);
      }
    }
    const url = query ? `query=` + params.join(",") + "&" : "";

    let headers = new HttpHeaders({
      Accept: type === "csv" ? "text/csv" : "",
    });

    let responseType = `${type === "csv" ? "text" : "blob"}` as "json";

    return this.http.get<any>(
      `${environment.transactionManagement}/otm/api/v1/transactions/file/booking-party/${type}/service-level?bookingPartyId=${bookingPartyId}&${url}${payload?.orderBy}pageNumber=${payload?.pageNumber}&pageSize=${payload?.pageSize}`,
      { headers, responseType: responseType }
    );
  }

  getTripSummaryByReservationId(reservationId): Observable<any> {
    return this.http.get<any>(
      `${environment.truckingService}/oat/api/v1/GetServiceDetails-ReservationId?reservationId=${reservationId}`
    );
  }

  getCountryList(name = ""): Observable<any> {
    return this.http.get<any>(
      `${environment.globalCMS}/global/cms/api/v2/country/filter?countryName=${name}`
    );
  }

  getStateList(id, name = ""): Observable<any> {
    return this.http.get<any>(
      `${environment.globalCMS}/global/cms/api/v2/country/${id}/states?stateName=${name}`
    );
  }

  getCityList(id, name = ""): Observable<any> {
    return this.http.get<any>(
      `${environment.globalCMS}/global/cms/api/v2/state/${id}/cities?cityName=${name}`
    );
  }

  getPostalList(id, name = "", pageSize?): Observable<any> {
    return this.http.get<any>(
      `${environment.globalCMS}/global/cms/api/v2/postal/${id}/postal-codes?search=${name}&pageSize=${pageSize ?? 5000}`
    );
  }

  getUnlocodes(countryName, location, division, code, filter): Observable<any> {
    return this.http.get<any>(
      `${environment.globalCMS}/global/cms/api/v2/unlocode/filter?search=${filter}&code=${code}&location=${location}&division=${division}&country=${countryName}`
    );
  }
  outboundBookingCancellation(reservationId: string, payload, serviceTicketId, action) {
    return this.http.post<any>(
      `${environment.seaFreightService}/sfs/api/v1/sea-freight/${reservationId}/${serviceTicketId}/${action}-booking-cancellation`,
      payload
    );
  }

  partnerCode(payload) {
    return this.http.put<any>(
      `${environment.clientManagement}/clients/api/v1/guest/partner-code`,
      payload
    );
  }
}
